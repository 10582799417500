import React from 'react';

import { Navigate, useParams } from 'react-router-dom';

import ResetPasswordForm from 'components/UI/organisms/ResetPasswordForm/ResetPasswordForm';
import { BasePageProps } from 'constants/_types/BasePageProps';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ResetPasswordFormPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);

  const { uid, token } = useParams();
  if (!uid || !token) return <Navigate to={PATHS.AUTH_LOGIN} />;
  return <ResetPasswordForm token={token} uid={uid} />;
};

export default ResetPasswordFormPage;
