import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import removeTrailingSlash from 'helpers/removeTrailngSlash/removeTrailingSlash';
import stringToBool from 'helpers/stringToBool/stringToBool';

// TEMPORARY SOLUTION FOR HIDE LANGUAGE CHANGER
if (stringToBool(process.env.REACT_APP_DISABLE_LANGUAGE)) {
  i18n
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      backend: {
        loadPath: `${removeTrailingSlash(process.env.REACT_APP_API_BASE_URL)}/internal/translations/pl/{{ns}}.json`,
      },
      lng: 'pl',
      load: 'languageOnly',
      fallbackLng: 'pl',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
} else {
  i18n
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      backend: {
        loadPath: `${removeTrailingSlash(process.env.REACT_APP_API_BASE_URL)}/internal/translations/{{lng}}/{{ns}}.json`,
      },
      load: 'languageOnly',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
}

export default i18n;
