const stringToBool = (string?: string): boolean => {
  switch (string) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return false;
  }
};

export default stringToBool;
