import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ authorized: boolean }>()((theme, { authorized }) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  toolbarRoot: {
    display: 'grid',
    gridTemplateColumns: authorized ? '70px 1fr' : '1fr',
    alignItems: 'center',
    justifyItems: 'center',
    gridGap: theme.spacing(2),
    padding: authorized ? theme.spacing(0, 3, 0, 0) : theme.spacing(0, 3),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: authorized ? '50px 1fr' : '1fr',
      gridGap: theme.spacing(1),
    },
  },
  logo: {
    height: '32px',
    [theme.breakpoints.down('sm')]: {
      height: '24px',
    },
  },
  menuIcon: {
    fontSize: '2.1875rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  },
  side: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
