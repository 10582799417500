import React, { useEffect } from 'react';

import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import { GlobalDialog, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import LayoutAuthorized from 'components/root/LayoutAuthorized/LayoutAuthorized';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import PATHS, { AUTH_PREFIX } from 'constants/router/PATHS';

const ROUTES_WITHOUT_POLICY = [PATHS.ROOT, PATHS.MY_PROFILE, PATHS.MY_PROFILE_GENERAL];

const PrivateRoute: React.FC = () => {
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();
  const { brandInfo } = useBrandContext();
  const { userInfo } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    if (userInfo.isAuthorized && !userInfo?.hasPhoneVerified && brandInfo?.config.registration_required_phone_number) {
      addToGlobalDialogQueue({ type: GlobalDialog.VERIFY_MOBILE_NUMBER });
    }
    if (userInfo.isAuthorized && !userInfo?.hasObligatoryConsents) {
      addToGlobalDialogQueue({ type: GlobalDialog.MISSING_AGREEMENTS });
    }
  }, [userInfo]);

  if (userInfo.isAuthorized === false) return <Navigate to={`/${AUTH_PREFIX}`} state={{ from: location }} replace />;

  if (userInfo.isAuthorized === null || userInfo.hasActivePolicy === null) {
    return <LoaderOverlay />;
  }

  if (brandInfo?.config.is_policy_required && !userInfo.hasActivePolicy && !ROUTES_WITHOUT_POLICY.includes(location.pathname)) {
    return <Navigate to={PATHS.ROOT} state={{ from: location }} replace />;
  }

  return (
    <LayoutAuthorized>
      <Outlet />
    </LayoutAuthorized>
  );
};

export default PrivateRoute;
