import React from 'react';

import { useLocation, Navigate, Outlet, useMatch } from 'react-router-dom';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import LayoutUnauthorized from 'components/root/LayoutUnauthorized/LayoutUnauthorized';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import PATHS from 'constants/router/PATHS';

const AuthPage: React.FC = () => {
  const { userInfo, logout } = useAuthContext();
  const location = useLocation();
  const isPasswordResetForm = !!useMatch(PATHS.AUTH_RESET_PASSWORD_FORM);

  if (userInfo.isAuthorized === null) return <LoaderOverlay />;

  if (userInfo.isAuthorized) {
    if (isPasswordResetForm) {
      logout();
      return (
        <LayoutUnauthorized>
          <Outlet />
        </LayoutUnauthorized>
      );
    }

    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return (
    <LayoutUnauthorized>
      <Outlet />
    </LayoutUnauthorized>
  );
};

export default AuthPage;
