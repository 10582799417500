import React, { useMemo } from 'react';

import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import users from 'api/users';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import PATHS from 'constants/router/PATHS';
import authMessages from 'translations/common/auth.mjs';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import resetPasswordMessages from 'translations/specific/resetPassword.mjs';

type EmailResetFormInput = {
  login_identifier: string;
};

const ResetPasswordRequestForm: React.FC = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation('Reset password request', users.passwordResetRequest(), {
    onSuccess: () => {
      navigate(PATHS.AUTH_RESET_PASSWORD_SUCCESS);
    },
    onError: () => {
      enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    },
  });
  const defaultLoginIdentifier = useMemo(() => {
    const state = location.state as { loginIdentifier: string };
    return state && state.loginIdentifier ? state.loginIdentifier : '';
  }, []);

  const { control, handleSubmit } = useForm<EmailResetFormInput>({
    defaultValues: { login_identifier: defaultLoginIdentifier },
  });

  const onSubmit: SubmitHandler<EmailResetFormInput> = async data => {
    if (!executeRecaptcha) enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    else {
      const recaptcha = await executeRecaptcha('Reset_password_request');
      mutation.mutate({ ...data, recaptcha });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h3' component='h1' align='center' marginBottom={2}>
        {t(resetPasswordMessages.header)}
      </Typography>
      <SimpleFormWrapper>
        <Typography variant='body2' align='center' mb={1}>
          {t(resetPasswordMessages.instruction)}
        </Typography>
        <FormInputText required name='login_identifier' control={control} label={t(authMessages.emailOrAuthIdentifier)} />
        <Button variant='contained' disabled={mutation.isLoading} type='submit'>
          {t(generalMessages.submit)}
        </Button>
      </SimpleFormWrapper>
    </form>
  );
};

export default ResetPasswordRequestForm;
