import { CSSObject, Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const openedMixin = (theme: Theme): CSSObject => ({
  width: LAYOUT_SIZES.SIDE_MENU.open,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: LAYOUT_SIZES.SIDE_MENU.closed,
});

const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  drawer: {
    width: LAYOUT_SIZES.SIDE_MENU.open,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(isOpen &&
      ({
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      } as Record<any, any>)),
    ...(!isOpen &&
      ({
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      } as Record<any, any>)),
  },
  drawerInner: {
    display: 'grid',
    padding: theme.spacing(0, 2),
    alignContent: 'flex-start',
    gridGap: theme.spacing(2),
    background: theme.palette.background.default,
    paddingTop: `calc(${LAYOUT_SIZES.HEADER.lg}px + ${theme.spacing(2)})`,
  },
}));

export default useStyles;
