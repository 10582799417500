import React from 'react';

import useStyles from './CardGrid.styles';

type Props = {
  children: React.ReactNode;
  columns?: number;
};

const CardGrid: React.FC<Props> = ({ children, columns = 3 }) => {
  const { classes } = useStyles({ columns });
  return <div className={classes.root}>{children}</div>;
};

export default CardGrid;
