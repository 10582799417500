import React, { useState } from 'react';

import { Button, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CardTitle from 'components/UI/molecules/CardTitle/CardTitle';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

import PasswordChangeDialog from '../_dialogs/PasswordChangeDialog/PasswordChangeDialog';
import TermsAgreementDialog from '../_dialogs/TermsAgreementDialog/TermsAgreementDialog';
import useStyles from './UserSettingsCard.styles';

enum Dialog {
  termsAgreement = 'termsAgreement',
  passwordChange = 'passwordChange',
}

const UserSettingsCard: React.FC = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState<Dialog | null>(null);

  const closeDialog = () => setDialog(null);
  const openTermsAgreementDialog = () => setDialog(Dialog.termsAgreement);
  const openPasswordChangeDialog = () => setDialog(Dialog.passwordChange);

  const { classes } = useStyles();
  return (
    <Card>
      <CardTitle title={t(userSettingsMessages.cardTitle)} />
      <CardContent>
        <div className={classes.buttonsWrapper}>
          <Button onClick={openTermsAgreementDialog}>{t(userSettingsMessages.termsAgreementChange)}</Button>
          <Button onClick={openPasswordChangeDialog}>{t(userSettingsMessages.passwordChange)}</Button>
        </div>
      </CardContent>
      <TermsAgreementDialog isOpen={dialog === Dialog.termsAgreement} close={closeDialog} />
      <PasswordChangeDialog isOpen={dialog === Dialog.passwordChange} close={closeDialog} />
    </Card>
  );
};

export default UserSettingsCard;
