import React, { useMemo } from 'react';

import { Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import LanguageChanger from 'components/UI/molecules/LanguageChanger/LanguageChanger';
import stringToBool from 'helpers/stringToBool/stringToBool';
import useDocumentsAgreementsQuery from 'hooks/useDocumentsAgreementsQuery/useDocumentsAgreementsQuery';
import getMainDocumentHref from 'services/getMainDocumentHref/getMainDocumentHref';
import languageMessages from 'translations/specific/languageMessages.mjs';

import useStyles from './AppFooter.styles';

const AppFooter: React.FC = () => {
  const { t } = useTranslation();
  const { brandInfo } = useBrandContext();
  const { documentsAgreements } = useDocumentsAgreementsQuery(['privacy_policy', 'terms_and_conditions'], { staleTime: 60 * 60 * 1000 });

  const documentHrefs = useMemo(
    () => ({
      privacyPolicy: getMainDocumentHref(documentsAgreements?.privacy_policy),
      termsAndConditions: getMainDocumentHref(documentsAgreements?.terms_and_conditions),
    }),
    [documentsAgreements],
  );

  const { classes, cx } = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth='xl' className={classes.content}>
        {brandInfo?.brand && (
          <div className={classes.contentBlock}>
            <Typography variant='body2'>{brandInfo?.brand.full_company_name}</Typography>
            <Typography variant='body2' component='a' href={`tel:${brandInfo?.brand.footer_phone_number}`}>
              {brandInfo?.brand.footer_phone_number}
            </Typography>
            <Typography variant='body2' component='a' href={`mailto:${brandInfo?.brand.footer_email}`} target='_blank'>
              {brandInfo?.brand.footer_email}
            </Typography>
          </div>
        )}
        <ul className={cx(classes.contentBlock)}>
          <li>
            <Typography component='a' href={documentHrefs.termsAndConditions} target='_blank' variant='body2'>
              Regulamin
            </Typography>
          </li>
          <li>
            <Typography component='a' href={documentHrefs.privacyPolicy} target='_blank' variant='body2'>
              Polityka prywatności
            </Typography>
          </li>
        </ul>
        <ul className={cx(classes.contentBlock, classes.alignRight)}>
          <Typography component='li' variant='body2'>
            MDT Net
          </Typography>
          <Typography component='li' variant='body2'>
            ul. Pokorna 2/U2
          </Typography>
          <Typography component='li' variant='body2'>
            00-199 Warszawa
          </Typography>
          <li>
            <Typography component='a' href='tel:+48 22 247 20 69' variant='body2'>
              +48 22 247 20 69
            </Typography>
          </li>
          <li>
            <Typography component='a' href='mailto:info@mdtnet.eu' target='_blank' variant='body2'>
              info@mdtnet.eu
            </Typography>
          </li>
        </ul>
        {/* TEMPORARY SOLUTION FOR HIDE LANGUAGE CHANGER */}
        {!stringToBool(process.env.REACT_APP_DISABLE_LANGUAGE) && (
          <div className={classes.languageChange}>
            <Typography variant='body2'>{t(languageMessages.changeLanguage)}</Typography>
            <LanguageChanger />
          </div>
        )}
      </Container>
    </div>
  );
};

export default AppFooter;
