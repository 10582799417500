import validationMessages from 'translations/common/validation.mjs';

const MIN_NUMBER_LENGTH = 9;
const MAX_NUMBER_LENGTH = 12;

const validatePhoneNumber = (phoneNumber: string): boolean | string[] => {
  const rawNumber = phoneNumber.split(' ').slice(1).join(' ').replace(/-/g, '').replace(/ /g, '');
  const rawNumberLength = rawNumber.length;
  return rawNumberLength < MIN_NUMBER_LENGTH || rawNumberLength > MAX_NUMBER_LENGTH ? validationMessages.phoneNumber.invalid : true;
};

export default validatePhoneNumber;
