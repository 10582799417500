import React from 'react';

import { Container } from '@mui/material';

import CardGrid from 'components/UI/organisms/CardGrid/CardGrid';
import PatientCard from 'components/UI/organisms/PatientCard/PatientCard';
import UserSettingsCard from 'components/UI/organisms/UserSettingsCard/UserSettingsCard';
import { BasePageProps } from 'constants/_types/BasePageProps';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const MyProfileGeneralPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);

  return (
    <Container>
      <CardGrid columns={2}>
        <PatientCard />
        <UserSettingsCard />
      </CardGrid>
    </Container>
  );
};

export default MyProfileGeneralPage;
