import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ minHeight?: string }>()((theme, { minHeight }) => ({
  inner: {
    height: '100%',
    minHeight: minHeight || 'unset',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cover: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    left: '0',
    zIndex: theme.zIndex.tooltip + 1,
    background: theme.palette.background.default,
  },
}));

export default useStyles;
