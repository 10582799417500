const loginMessages = {
  header: [ 'login>>header', 'Logowanie' ],
  forgot_password_link: [ 'login>>forgot_password_link', 'Zapomniałem hasła' ],
  button_label: [ 'login>>button_label', 'Zaloguj' ],
  invalid_credentials: [ 'login>>invalid_credentials', 'Dane logowania są nieprawidłowe' ],
  footer: {
    register_link: [ 'login>>footer>>register_link', 'Zarejestruj się' ],
    register_link_label: [ 'login>>footer>>register_link_label', 'Nie masz konta?' ]
  }
}

export default loginMessages
