import { PaletteColor } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ triageColor: PaletteColor }>()((theme, { triageColor }) => ({
  root: {
    background: theme.palette.background.default,
    padding: theme.spacing(12, 8),
    display: 'grid',
    gridGap: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 4),
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 3),
    },
  },
  triageContainer: {
    marginTop: theme.spacing(1),
    background: theme.palette.grey[100],
    display: 'grid',
    gridTemplateColumns: '100px auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '80px auto',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
      gridTemplateRows: '80px auto',
    },
  },
  triageIndicator: {
    padding: theme.spacing(4, 2),
    backgroundColor: triageColor.main,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 3),
    },
  },
  triageMain: {
    padding: theme.spacing(5, 3),
    display: 'grid',
    gridGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  triageBrandText: {
    textAlign: 'center',
    background: theme.palette.grey[100],
    padding: theme.spacing(3, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2),
    },
  },
  card: {
    marginTop: theme.spacing(1),
    background: theme.palette.grey[100],
    padding: theme.spacing(3, 2),
    display: 'grid',
    gridGap: theme.spacing(3),
  },
  table: {
    background: theme.palette.grey[100],
  },
  icon: {
    fontSize: '12px',
    marginRight: theme.spacing(1),
    justifySelf: 'center',
  },
  dotIcon: {
    fontSize: '8px',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  checkIcon: {
    color: theme.palette.primary.main,
  },
  closeIcon: {
    color: theme.palette.error.main,
  },
  arrowIcon: {
    color: theme.palette.grey[600],
  },
}));

export default useStyles;
