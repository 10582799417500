import { useEffect } from 'react';

import { NestedRoute } from 'constants/router/routes';

const usePageConfig = (config?: NestedRoute) => {
  useEffect(() => {
    if (!config?.scrollRestoration) {
      window.scrollTo(0, 0);
    }
  }, [config]);
};

export default usePageConfig;
