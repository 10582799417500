import React from 'react';

import { useQuery } from 'react-query';

import symptomChecker from 'api/symptomChecker';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import GeneralErrorContent from 'components/root/GeneralError/_components/GeneralErrorContent';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import SymptomChecker from 'components/UI/organisms/SymptomChecker/SymptomChecker';
import { BasePageProps } from 'constants/_types/BasePageProps';
import { SupportedLanguages } from 'constants/translations/_types';
import removeTrailingSlash from 'helpers/removeTrailngSlash/removeTrailingSlash';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const getSrc = ({ languageCode, token }: { languageCode: SupportedLanguages; token: string }) => {
  const base = removeTrailingSlash(process.env.REACT_APP_SYMPTOM_CHECKER_URL_BASE);
  return `${base}/form/${languageCode}/?token=${token}`;
};

const SymptomCheckerPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);

  const languageCode = useLanguageCode();
  const { patient } = usePatientContext();
  const { isLoading, data, isError } = useQuery('Get symptom checker token', symptomChecker.getAccessToken({ patient: patient?.id }), {
    refetchOnWindowFocus: false,
    enabled: !!patient,
  });

  if (isLoading) return <LoaderOverlay inner />;
  if (isError || !data) return <GeneralErrorContent standAlone />;
  return <SymptomChecker src={getSrc({ languageCode, token: data.data.token })} />;
};

export default SymptomCheckerPage;
