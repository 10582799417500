import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  buttonsWrapper: {
    display: 'grid',
    justifyContent: 'flex-start',
    gridGap: theme.spacing(2),
  },
}));

export default useStyles;
