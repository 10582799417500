import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 4),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
}));

export default useStyles;
