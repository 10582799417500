import React, { useEffect, useMemo, useState } from 'react';

import { Button, CardContent, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import documents, { DocumentForContext } from 'api/documents';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import TermsAgreementForm from 'components/UI/organisms/TermsAgreementForm/TermsAgreementForm';
import { SupportedLanguages } from 'constants/translations/_types';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import menuMessages from 'translations/specific/menu.mjs';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

type Props = {
  flushGlobalDialogQueue?: () => void;
  close: () => void;
  isOpen: boolean;
  fillMissing?: boolean;
};

type TermsAgreementFormInput = {
  terms: {
    [key: string]: boolean;
  };
};

const filterLanguage = (documentsArray: DocumentForContext[], langCode: SupportedLanguages): DocumentForContext[] =>
  documentsArray.filter(({ language_code }) => language_code === langCode);

const checkIfShouldBeDisabled = (doc: DocumentForContext): boolean => !doc.is_revocable && !!doc.has_consent;

const FORM_ID = 'TermsAgreementDialog_form';

const TermsAgreementDialog: React.FC<Props> = ({ isOpen, flushGlobalDialogQueue, close, fillMissing }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, refreshUserInfo, logout } = useAuthContext();
  const { brandInfo } = useBrandContext();
  const langCode = useLanguageCode();
  const [initialFormData, setInitialFormData] = useState<{ [key: string]: boolean } | null>(null);

  const onLogout = () => {
    if (flushGlobalDialogQueue) flushGlobalDialogQueue();
    logout();
  };

  const { data: initialData, isLoading, refetch } = useQuery(`Get documents to all accept in ${langCode}`, documents.getAllDocuments());

  const { control, handleSubmit, setValue, reset } = useForm<TermsAgreementFormInput>({ defaultValues: { terms: {} } });

  const documentsToRender = useMemo(() => {
    if (!initialData) return null;
    return (
      [
        ...filterLanguage(initialData.data.terms_and_conditions, langCode),
        ...filterLanguage(initialData.data.privacy_policy, langCode),
        ...filterLanguage(initialData.data.marketing, langCode),
        ...filterLanguage(initialData.data.patient_profile, langCode),
        ...filterLanguage(initialData.data.services, langCode),
      ]
        .map(doc => ({ ...doc, shouldBeDisabled: checkIfShouldBeDisabled(doc) }))
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.shouldBeDisabled === b.shouldBeDisabled ? 0 : !a.shouldBeDisabled ? -1 : 1))
    );
  }, [initialData]);

  useEffect(() => {
    if (isOpen) refetch();
  }, [isOpen]);

  useEffect(() => {
    if (documentsToRender) {
      const initialValue: { [key: string]: boolean } = {};
      documentsToRender.forEach(({ id, has_consent }) => {
        setValue(`terms.${id}`, !!has_consent);
        initialValue[id] = !!has_consent;
      });
      setInitialFormData(initialValue);
    }
  }, [documentsToRender]);

  const onSubmit: SubmitHandler<TermsAgreementFormInput> = async formData => {
    if (!initialFormData) return;
    const apiCalls: Promise<any>[] = [];
    Object.entries(initialFormData).forEach(([key, value]) => {
      if (!isEqual(initialFormData[key], formData.terms[key])) {
        const promiseToPush = formData.terms[key]
          ? documents.updateDocumentsForContext(Number(key))()
          : documents.deleteDocumentsForContext(Number(key))();
        apiCalls.push(promiseToPush);
      }
    });
    try {
      const response = await Promise.all(apiCalls);
      if (response.every(({ status }) => status < 300)) {
        enqueueSnackbar(t(snackbarMessages.success), { variant: 'success' });
        reset(formData);
        await Promise.all([refetch(), refreshUserInfo()]);
        close();
        setInitialFormData(formData.terms);
      } else {
        enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    }
  };

  return (
    <DialogWrapper
      disableClose={fillMissing}
      header={fillMissing ? t(userSettingsMessages.checkObligatoryConsentsTitle) : t(userSettingsMessages.termsAgreementChange)}
      isOpen={isOpen}
      close={close}
      dialogProps={{
        maxWidth: 'sm',
      }}
      actions={
        <>
          {fillMissing && (
            <Button disabled={isLoading} onClick={onLogout} variant='outlined'>
              {t(menuMessages.elements.logout)}
            </Button>
          )}
          <Button form={FORM_ID} type='submit' variant='contained' disabled={isLoading}>
            {t(generalMessages.save)}
          </Button>
        </>
      }
    >
      <ElementWithLoader isLoading={isLoading}>
        <CardContent>
          {fillMissing && (
            <Typography variant='body1' marginBottom={3} textAlign='center' marginX={1}>
              {t(userSettingsMessages.checkObligatoryConsentsBody, {
                originBrand: userInfo.originBrand,
                currentBrand: brandInfo?.name,
              })}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <TermsAgreementForm control={control} data={documentsToRender} fillMissing={fillMissing} />
          </form>
        </CardContent>
      </ElementWithLoader>
    </DialogWrapper>
  );
};

export default TermsAgreementDialog;
