import axios from 'axios';

import accessTokenStorage from 'storages/accessTokenStorage';
import refreshTokenStorage from 'storages/refreshTokenStorage';

const authProvider = {
  login: ({ access, refresh }: { access: string; refresh: string }, callback: () => void) => {
    axios.defaults.headers.common.Authorization = `Bearer ${access}`;
    accessTokenStorage.set(access);
    refreshTokenStorage.set({ data: refresh });
    callback();
  },
  logout: (callback: () => void) => {
    axios.defaults.headers.common.Authorization = false;
    accessTokenStorage.destroy();
    refreshTokenStorage.destroy();
    callback();
  },
  validate: (callback: (param: boolean) => void) => {
    const initialAccessToken = accessTokenStorage.get();
    const initialRefreshToken = refreshTokenStorage.get();
    const isAuthorized = !!initialAccessToken && !!initialRefreshToken;
    if (isAuthorized) axios.defaults.headers.common.Authorization = `Bearer ${initialAccessToken}`;
    else axios.defaults.headers.common.Authorization = false;

    callback(isAuthorized);
  },
};

export default authProvider;
