import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AppTopBar from 'components/UI/organisms/AppTopBar/AppTopBar';
import MainMenuDesktop from 'components/UI/organisms/MainMenuDesktop/MainMenuDesktop';
import MainMenuMobile from 'components/UI/organisms/MainMenuMobile/MainMenuMobile';

import useStyles from './LayoutAuthorized.styles';

type Props = {
  children: React.ReactNode;
};

const LayoutAuthorized: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <AppTopBar authorized />
      {downSM ? <MainMenuMobile /> : <MainMenuDesktop />}
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default LayoutAuthorized;
