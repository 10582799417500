import { TranslatableOption } from 'constants/_types/DropownOptions';
import { PatientDocument } from 'constants/_types/PatientDocuments';
import patientMessages from 'translations/specific/patient.mjs';

const patientDocumentTypes: TranslatableOption[] = [
  {
    value: PatientDocument.PASSPORT,
    labelKey: patientMessages.documentTypes.passport,
  },
  {
    value: PatientDocument.ID,
    labelKey: patientMessages.documentTypes.id,
  },
  {
    value: PatientDocument.ID_CARD,
    labelKey: patientMessages.documentTypes.idCard,
  },
  {
    value: PatientDocument.DRIVING_LICENCE,
    labelKey: patientMessages.documentTypes.drivingLicence,
  },
];

export default patientDocumentTypes;
