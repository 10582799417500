import { isNil } from 'lodash';

export type Params = { [key: string]: string | number | boolean };
type CreateUrlWithParams = (url: string, params?: Params, removeEmptyValues?: boolean) => string;

const createUrlWithParams: CreateUrlWithParams = (url, params, removeEmptyValues = true) => {
  if (!url) throw new Error('Url is required');
  if (!params) return url;
  if (removeEmptyValues) {
    Object.entries(params).forEach(([key, value]) => {
      // eslint-disable-next-line no-param-reassign
      if (isNil(value)) delete params[key];
    });
  }
  const urlParams = new URLSearchParams(params as unknown as URLSearchParams).toString();
  return `${url}?${urlParams}`;
};

export default createUrlWithParams;
