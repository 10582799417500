import React from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import { BasePageProps } from 'constants/_types/BasePageProps';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import generalMessages from 'translations/common/general.mjs';
import resetPasswordMessages from 'translations/specific/resetPassword.mjs';

const ResetPasswordFormSuccessPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);

  const { t } = useTranslation();
  return (
    <SimpleFormWrapper>
      <Typography align='center'>{t(resetPasswordMessages.reset_form_successful)}</Typography>
      <Button component={Link} to={PATHS.AUTH_LOGIN} variant='contained'>
        {t(generalMessages.ok)}
      </Button>
    </SimpleFormWrapper>
  );
};

export default ResetPasswordFormSuccessPage;
