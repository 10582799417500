import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'hidden',
    padding: theme.spacing(1, 1.5),
    width: '100%',
    transition: theme.transitions.create(['width', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minWidth: 'unset',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'flex-start',

    '&.active': {
      background: theme.palette.primary.light,
    },
  },

  label: {
    display: 'inline-block',
    width: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
    },
  },
}));

export default useStyles;
