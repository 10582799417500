import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import { BasePageProps } from 'constants/_types/BasePageProps';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import myProfileMessages from 'translations/specific/myProfile.mjs';

const MyProfilePage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);

  const { userInfo } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const links = useMemo(() => {
    if (userInfo?.hasActivePolicy) {
      return [
        { label: t(myProfileMessages.navigation.general), to: PATHS.MY_PROFILE_GENERAL },
        // {
        //   label: t(myProfileMessages.navigation.policyAndSubscriptions),
        //   to: PATHS.MY_PROFILE_POLICY_SUBSCRIPTIONS,
        // },
      ];
    }
    return [{ label: t(myProfileMessages.navigation.general), to: PATHS.MY_PROFILE_GENERAL }];
  }, [userInfo]);
  useEffect(() => {
    navigate(PATHS.MY_PROFILE_GENERAL);
  }, []);
  return (
    <MainContentWrapper subNavigationTitle={t(myProfileMessages.navigation.title)} links={links}>
      <Outlet />
    </MainContentWrapper>
  );
};

export default MyProfilePage;
