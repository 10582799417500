const DATE_FORMATS = {
  DISPLAY: 'dd.MM.yyyy',
  DISPLAY_TIME: 'dd.MM.yyyy-HH:mm',
  DISPLAY_LONG: 'd MMMM yyyy',
  INPUT: 'dd/MM/yyyy',
  INPUT_TIME: 'dd/MM/yyyy HH:mm',
  API_DATE: 'yyyy-MM-dd',
};

export default DATE_FORMATS;
