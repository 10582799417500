import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ disableClose?: boolean }>()((theme, { disableClose }) => ({
  paper: {
    padding: disableClose ? theme.spacing(2, 0) : theme.spacing(0, 0, 2),
  },
  dialogInnerWrapper: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
    },
  },
  dialogContent: {
    display: 'block',
    alignItems: 'center',
  },

  logoBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(5),
  },
  logo: {
    height: '28px',
  },
  closeBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
