import axios from 'axios';

import { SupportedLanguages } from 'constants/translations/_types';

import { DefaultApiResponse } from './_types';

const endpoints = {
  consents: 'api/v1/document-consents/',
  documents: 'api/v1/documents/',
};

export type DocumentContext = 'terms_and_conditions' | 'privacy_policy' | 'marketing' | 'patient_profile' | 'services';

type GetConsentsQuery = () => Promise<DefaultApiResponse<unknown>>;

export type DocumentForContext = {
  category: DocumentContext;
  display_text: string;
  file: string;
  id: number;
  is_required: boolean;
  is_revocable: boolean;
  is_main: boolean;
  language_code: SupportedLanguages;
  has_consent?: boolean;
  shouldBeDisabled?: boolean;
};
type GetDocumentsForContextQuery = () => Promise<DefaultApiResponse<DocumentForContext[]>>;

type GetAllDocumentsQuery = () => Promise<DefaultApiResponse<{ [key in DocumentContext]: DocumentForContext[] }>>;

type UpdateDocumentsForContextQuery = (data?: { patient: number }) => Promise<DefaultApiResponse<unknown>>;

const users = {
  getConsents: (): GetConsentsQuery => () => axios.get<GetConsentsQuery, DefaultApiResponse<unknown>>(endpoints.consents),
  getDocumentsForContext:
    (context: DocumentContext): GetDocumentsForContextQuery =>
    () =>
      axios.get<GetDocumentsForContextQuery, DefaultApiResponse<DocumentForContext[]>>(`${endpoints.documents}${context}`),
  getAllDocuments: (): GetAllDocumentsQuery => () =>
    axios.get<GetAllDocumentsQuery, DefaultApiResponse<{ [key in DocumentContext]: DocumentForContext[] }>>(`${endpoints.documents}all`),
  updateDocumentsForContext:
    (agreementId: number): UpdateDocumentsForContextQuery =>
    data =>
      axios.post<UpdateDocumentsForContextQuery, DefaultApiResponse<unknown>>(`${endpoints.documents}${agreementId}`, data),
  deleteDocumentsForContext:
    (agreementId: number): UpdateDocumentsForContextQuery =>
    data =>
      axios.delete<UpdateDocumentsForContextQuery, DefaultApiResponse<unknown>>(`${endpoints.documents}${agreementId}`, { data }),
};

export default users;
