import React from 'react';

import useStyles from './DialogFormWrapper.styles';

type Props = {
  id?: string;
  children: React.ReactNode;
  onSubmit?: () => void;
};

const DialogFormWrapper: React.FC<Props> = ({ id, children, onSubmit }) => {
  const { classes } = useStyles();
  return (
    <form id={id} className={classes.root} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default DialogFormWrapper;
