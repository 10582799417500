import axios from 'axios';

import { SymptomCheckerTriageLevels } from 'constants/_types/SymptomCheckerTriageLevels';
import { SupportedLanguagesEnum } from 'constants/translations/_types';

import { DefaultApiResponse } from './_types';

const endpoints = {
  getInfo: 'api/v1/companies/',
};

export type Brand = {
  id: number;
  brand: {
    id: number;
    logo_left: string;
    logo_right: string;
    favicon: string;
    full_company_name: string;
    footer_phone_number: string;
    footer_email: string;
    symptom_checker_triage: {
      [k1 in SymptomCheckerTriageLevels]: {
        [k2 in SupportedLanguagesEnum]: string;
      };
    };
  };
  config: {
    id: number;
    display_referral_form: boolean;
    force_2fa: boolean;
    registration_required_phone_number: boolean;
    required_patient_fields: string[];
    is_policy_required: boolean;
  };
  name: string;
  subdomain: string;
};
type GetInfoQuery = () => Promise<DefaultApiResponse<Brand>>;

const branding = {
  getInfo:
    (brand: string): GetInfoQuery =>
    () =>
      axios.get<GetInfoQuery, DefaultApiResponse<Brand>>(`${endpoints.getInfo}${brand}/`),
};

export default branding;
