import { Theme } from '@mui/material/styles';

import symptomCheckerReportsMessages from 'translations/specific/symptomCheckerReports.mjs';

import { SimpleResolver } from '../_types';

export type ColorResolver = (t: Theme, value?: number) => string;

const resolveProbabilityName: SimpleResolver = (t, probability) => {
  if (!probability) return '';
  if (probability > 0.75) return t(symptomCheckerReportsMessages.probabilities.high);
  if (probability > 0.5) return t(symptomCheckerReportsMessages.probabilities.medium);
  return t(symptomCheckerReportsMessages.probabilities.low);
};

const resolveProbabilityColor: ColorResolver = (theme, probability) => {
  const { high, medium, low } = theme.palette.probability;
  if (!probability) return theme.palette.grey[400];
  if (probability > 0.75) return high.main;
  if (probability > 0.5) return medium.main;
  return low.main;
};

export { resolveProbabilityName, resolveProbabilityColor };
