import React, { useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import MainContentWrapper, { Message } from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import { BasePageProps } from 'constants/_types/BasePageProps';
import DashboardVersions from 'constants/_types/DashboardVersions';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import dashboardMessages from 'translations/specific/dashboard.mjs';

const DashboardDefault = React.lazy(() => import('./_versions/DashboardDefault/DashboardDefault'));
const DashboardInterrisk = React.lazy(() => import('./_versions/DashboardInterrisk/DashboardInterrisk'));

const renderDashboard: (disabled: boolean) => React.ReactNode = disabled => {
  switch (process.env.REACT_APP_DASHBOARD_VERSION) {
    case DashboardVersions.INTERRISK:
      return <DashboardInterrisk disabled={disabled} />;
    default:
      return <DashboardDefault disabled={disabled} />;
  }
};

const isReducedPadding = DashboardVersions.INTERRISK === process.env.REACT_APP_DASHBOARD_VERSION;

const DashboardPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);
  const { t, i18n } = useTranslation();
  const { userInfo } = useAuthContext();
  const { brandInfo } = useBrandContext();

  const messages = useMemo(() => {
    const tempMessages: Message[] = [];
    if (!userInfo.isAuthorized) return tempMessages;
    if (!userInfo?.hasFullAccount) {
      const missingAccountFields: Message = {
        content: t(dashboardMessages.alerts.fillYourData.content, {
          missingFields: brandInfo?.config.required_patient_fields.join(', '),
        }),
        severity: 'error',
        description: t(dashboardMessages.alerts.fillYourData.description, {
          missingFields: brandInfo?.config.required_patient_fields.join(', '),
        }),
        action: (
          <Link to={PATHS.MY_PROFILE_GENERAL} state={{ openDialog: true }}>
            <Button variant='contained' color='error'>
              {t(dashboardMessages.alerts.fillYourData.actionButton)}
            </Button>
          </Link>
        ),
      };
      tempMessages.push(missingAccountFields);
      return tempMessages;
    }
    if (!userInfo?.hasActivePolicy && brandInfo?.config.is_policy_required) {
      const noActivePolicyMessage: Message = {
        content: t(dashboardMessages.alerts.missingPolicy.content),
        severity: 'error',
        description: t(dashboardMessages.alerts.missingPolicy.description),
      };
      tempMessages.push(noActivePolicyMessage);
    }
    return tempMessages;
  }, [userInfo, i18n.language]);

  const disabled = useMemo(
    () => (brandInfo?.config.is_policy_required && !userInfo?.hasActivePolicy) || !userInfo?.hasFullAccount,
    [userInfo],
  );

  return (
    <MainContentWrapper messages={messages} isReducedPadding={isReducedPadding}>
      <React.Suspense fallback={<LoaderOverlay inner minHeight='80vh' />}>{renderDashboard(disabled)}</React.Suspense>
    </MainContentWrapper>
  );
};

export default DashboardPage;
