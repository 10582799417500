import React from 'react';

import { Typography } from '@mui/material';

import useStyles from './CardTitle.styles';

type Props = {
  title: string;
  leftAdornment?: React.ReactElement;
  rightAdornment?: React.ReactElement;
};

const CardTitle: React.FC<Props> = ({ title, leftAdornment, rightAdornment }) => {
  const { classes, cx } = useStyles();
  return (
    <header className={classes.root}>
      <div className={classes.icon}>{leftAdornment && leftAdornment}</div>
      <Typography align='center' variant='h3' component='h2' className={classes.text}>
        {title}
      </Typography>
      <div className={cx(classes.icon, classes.editIcon)}>{rightAdornment && rightAdornment}</div>
    </header>
  );
};

export default CardTitle;
