import React, { useState } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { QueryKey, useMutation, useQueryClient } from 'react-query';

import reports from 'api/reports';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import downloadBlob from 'helpers/downloadBlob/downloadBlob';
import useGetReportBlob from 'hooks/useGetReportBlob/useGetReportBlob';
import dashboardMessages from 'translations/specific/dashboard.mjs';

import useStyles from './ReportsServiceButtons.styles';

export type ButtonsReportProp = {
  id: number;
  isMedicalRecord: boolean;
  isHidden: boolean;
  createdAt: string;
};

export type TempButtonsReportState = {
  isMedicalRecord: boolean;
  isHidden: boolean;
} | null;

type Props = {
  report: ButtonsReportProp;
  queryKey?: QueryKey;
};

const ReportsServiceButtons: React.FC<Props> = ({ report, queryKey }) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const getReportBlob = useGetReportBlob();
  const queryClient = useQueryClient();

  const [tempState, setTempState] = useState<TempButtonsReportState>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mutation = useMutation(reports.updateReport(report.id), {
    onMutate: async mutationInput => {
      setIsLoading(true);
      setTempState({
        isMedicalRecord: mutationInput.is_medical_record as boolean,
        isHidden: mutationInput.is_hidden as boolean,
      });
    },
    onSuccess: async () => {
      if (queryKey) await queryClient.invalidateQueries(queryKey);
    },
    onSettled: async () => {
      setIsLoading(false);
      setTempState(null);
    },
  });

  const onMedicalRecordToggle = async () => {
    if (isLoading) return;
    mutation.mutate({
      is_hidden: report.isHidden,
      is_medical_record: !report.isMedicalRecord,
      patient: patient.id,
    });
  };

  const onHide = async () => {
    if (isLoading) return;
    const confirm = report.isHidden
      ? true
      : await showConfirmationDialog({
          title: t(dashboardMessages.reportsCard.deleteConfirmation.title),
          body: t(dashboardMessages.reportsCard.deleteConfirmation.body),
        });
    if (confirm) {
      mutation.mutate({
        is_hidden: !report.isHidden,
        is_medical_record: report.isMedicalRecord,
        patient: patient.id,
      });
    }
  };

  const onDownload = async () => {
    if (isLoading) return;
    const { blob } = await getReportBlob(report.id);
    downloadBlob(blob, `${format(new Date(report.createdAt), DATE_FORMATS.DISPLAY_TIME)}-raport.pdf`);
  };

  const dataSource = tempState || report;
  const { isMedicalRecord, isHidden } = dataSource;

  const { classes } = useStyles();
  return (
    <div className={classes.buttons}>
      <Tooltip
        enterDelay={750}
        title={
          isMedicalRecord
            ? (t(dashboardMessages.reportsCard.actions.private) as string)
            : (t(dashboardMessages.reportsCard.actions.public) as string)
        }
      >
        <IconButton onClick={onMedicalRecordToggle}>{isMedicalRecord ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}</IconButton>
      </Tooltip>
      <Tooltip enterDelay={750} title={t(dashboardMessages.reportsCard.actions.download) as string}>
        <IconButton onClick={onDownload}>
          <FileDownloadOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        enterDelay={750}
        title={
          isHidden ? (t(dashboardMessages.reportsCard.actions.show) as string) : (t(dashboardMessages.reportsCard.actions.hide) as string)
        }
      >
        <IconButton onClick={onHide}>{isHidden ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}</IconButton>
      </Tooltip>
    </div>
  );
};

export default ReportsServiceButtons;
