import React from 'react';

import { DatePicker, DateTimePicker } from '@mui/lab';
import { FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import generalMessages from 'translations/common/general.mjs';

type Props = {
  label?: string;
  helperText?: string;
  error?: string;
  onChange: (event: any) => void;
  value?: Date | null;
  minDate?: Date;
  fullWidth?: boolean;
  timePicker?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
};

const InputDate: React.FC<Props> = ({ label, helperText, error, onChange, value, minDate, fullWidth = true, timePicker, ...rest }) => {
  const { t } = useTranslation();

  const Component = timePicker ? DateTimePicker : DatePicker;
  return (
    <FormControl error={!!error} fullWidth={fullWidth}>
      <Component
        label={label}
        value={value || null}
        onChange={onChange}
        inputFormat={timePicker ? DATE_FORMATS.INPUT_TIME : DATE_FORMATS.INPUT}
        renderInput={params => <TextField fullWidth={fullWidth} {...params} error={!!error} />}
        minDate={minDate}
        okText={t(generalMessages.ok)}
        cancelText={t(generalMessages.cancel)}
        {...rest}
      />
      <InputFooter helperText={helperText} error={error} />
    </FormControl>
  );
};

export default InputDate;
