import React from 'react';

import { Card } from '@mui/material';

import CardTitle from '../CardTitle/CardTitle';
import useStyles from './CardWithCustomElements.styles';

type Props = {
  header: string;
  icon: React.FunctionComponent;
  disabled?: boolean;
  children: React.ReactNode;
  customFooter?: () => JSX.Element;
  maxHeight?: number | string;
  minHeight?: number | string;
};

const CardWithCustomElements: React.FC<Props> = ({ header, icon: Icon, children, disabled, customFooter, maxHeight, minHeight }) => {
  const { classes } = useStyles({ disabled, maxHeight, minHeight });
  return (
    <Card className={classes.root}>
      <CardTitle title={header} leftAdornment={<Icon />} />
      <div className={classes.content}>{children}</div>
      {customFooter && customFooter()}
    </Card>
  );
};

export default CardWithCustomElements;
