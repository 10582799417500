const generalMessages = {
  ok: [ 'general>>ok', 'Ok' ],
  cancel: [ 'general>>cancel', 'Anuluj' ],
  yes: [ 'general>>yes', 'Tak' ],
  no: [ 'general>>no', 'Nie' ],
  showAll: [ 'general>>showMore', 'Pokaż wszystkie' ],
  submit: [ 'general>>submit', 'Wyślij' ],
  uploadFiles: [ 'general>>uploadFiles', 'Przeciągnij i upuść pliki tutaj, bądź kliknij aby wybrać pliki' ],
  addMoreFiles: [ 'general>>addMoreFiles', 'Przeciągnij i upuść pliki tutaj, bądź kliknij aby dodać więcej plików' ],
  dropHere: [ 'general>>dropHere', 'Upuść tutaj' ],
  acceptedExtensions: [ 'general>>acceptedExtensions', 'Akceptowane rozszerzenia: {{extensions}}' ],
  noData: [ 'general>>noData', 'Brak danych' ],
  save: [ 'general>>save', 'Zapisz' ],
  agreementAcceptation: {
    content: [ 'general>>agreementAcceptation>>content', 'Zaakceptowany przez Ciebie regulamin usługi znajduje się' ],
    link: [ 'general>>agreementAcceptation>>link', 'tutaj' ],
  },
  gender: {
    gender: [ 'general>>gender>>gender', 'Płeć' ],
    m: [ 'general>>gender>>male', 'Mężczyzna' ],
    f: [ 'general>>gender>>female', 'Kobieta' ],
  },
  address: {
    city: [ 'general>>address>>city', 'Miasto' ],
    postCode: [ 'general>>address>>postCode', 'Kod pocztowy' ],
    street: [ 'general>>address>>street', 'Ulica' ],
    buildingNumber: [ 'general>>address>>buildingNumber', 'Numer budynku' ],
    apartmentNumber: [ 'general>>address>>apartmentNumber', 'Numer mieszkania' ],
    country: [ 'general>>address>>country', 'Kraj' ],
  },
  phoneNumber: [ 'general>>phoneNumber', 'Numer telefonu' ],
  pagination: {
    next: [ 'general>>pagination>>next', 'Dalej' ],
    previous: [ 'general>>pagination>>previous', 'Wstecz' ],
  },
  pageNotFound: ['general>>pageNotFound', 'Strona o podanym adresie nie istnieje.'],
  pageNotFoundBack: ['general>>pageNotFoundBack', 'Wróc do strony głównej'],
  generalError: ['general>>generalError', 'Coś poszło nie tak...']
}

export default generalMessages
