import React from 'react';

import { FormHelperText, Typography } from '@mui/material';

type Props = {
  helperText?: string;
  error?: string | boolean;
  className?: string;
  name?: string;
};

const InputFooter: React.FC<Props> = ({ helperText, error, className, name }) => (
  <FormHelperText className={className}>
    <Typography data-cy={`${name}_error`} variant='caption' color='error'>
      {error}
    </Typography>
    {!!error && !!helperText && ' - '}
    <Typography variant='caption' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
      {helperText}
    </Typography>
  </FormHelperText>
);

export default InputFooter;
