import React from 'react';

import { FormControl, Autocomplete, TextField, AutocompleteValue } from '@mui/material';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import { Option } from 'constants/_types/DropownOptions';

type Props = {
  label: string;
  helperText?: string;
  error?: string | boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  freeSolo?: boolean;
  options: Option[] | [];
  value?: string;
  onChange: (value: AutocompleteValue<any, any, any, any> | string | undefined) => void;
};

const InputAutocomplete: React.FC<Props> = ({ label, helperText, error, fullWidth = true, options, disabled, freeSolo, ...field }) => (
  <FormControl disabled={disabled} error={!!error} variant='outlined' fullWidth={fullWidth}>
    <Autocomplete
      freeSolo={freeSolo}
      renderInput={({ ...rest }) => <TextField error={!!error} autoComplete='none' label={label} {...rest} />}
      options={options}
      inputValue={field.value || ''}
      onChange={(e, value) => field.onChange(value)}
      onInputChange={(e, value) => field.onChange(value)}
    />
    <InputFooter helperText={helperText} error={error} />
  </FormControl>
);

export default InputAutocomplete;
