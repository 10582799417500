import isDevelopment from 'helpers/isDevelopment/isDevelopment';

type Unit = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks';

const getMs = (number: number, unit: Unit) => {
  if (!number || !unit) {
    throw new Error('Pass unit and number');
  }
  switch (unit) {
    case 'seconds':
      return number * 1000;
    case 'minutes':
      return number * 1000 * 60;
    case 'hours':
      return number * 1000 * 60 * 60;
    case 'days':
      return number * 1000 * 60 * 60 * 24;
    case 'weeks':
      return number * 1000 * 60 * 60 * 24 * 7;
    default:
      return number;
  }
};

type Config = {
  storage?: 'sessionStorage' | 'localStorage';
  trackTime?: boolean;
  parser?: (dataToParse: object | [] | string) => object;
};

type Data = {
  _stale?: boolean;
  _lastUpdated?: number;
  data?: object | string;
};

export default class StorageService {
  key: string;

  storage: 'sessionStorage' | 'localStorage';

  trackTime?: boolean;

  string?: boolean;

  parser?: (dataToParse: object | [] | string) => object;

  constructor(key: string, { storage = 'localStorage', trackTime = false, parser }: Config = {}) {
    this.key = key;
    this.storage = storage;
    this.trackTime = trackTime;
    this.parser = parser;
  }

  get = (number?: number, unit?: Unit) => {
    const { key, storage } = this;
    try {
      const serialized = window[storage].getItem(key);
      if (serialized === null) {
        return undefined;
      }
      const object: Data = this.string ? serialized : JSON.parse(serialized);
      if (this.trackTime && object._lastUpdated && number && unit) {
        object._stale = new Date().getTime() > object._lastUpdated + getMs(number, unit);
      }
      return object;
    } catch {
      return undefined;
    }
  };

  set = (inputData: { data?: object | string | number }) => {
    const { key, storage, trackTime } = this;
    const data: Data = this.parser ? this.parser(inputData) : inputData;
    try {
      if (trackTime) data._lastUpdated = new Date().getTime();
      const serialized = JSON.stringify(data);
      window[storage].setItem(key, serialized);
    } catch (error) {
      // eslint-disable-next-line no-console
      if (isDevelopment()) console.error(error);
    }
  };

  destroy = () => {
    const { key, storage } = this;
    window[storage].removeItem(key);
  };
}
