import React from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FormControl, InputLabel, Select, MenuItem, IconButton, SelectChangeEvent } from '@mui/material';
import { isNil } from 'lodash';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import { Option } from 'constants/_types/DropownOptions';

import useStyles from './InputDropdown.styles';

type Props = {
  label?: string;
  helperText?: string;
  error?: string | boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  options?: Option[];
  multiple?: boolean;
  value: string | undefined;
  clearable?: boolean;
  onChange?: (event: SelectChangeEvent) => void;
};

const noValue = (value?: string | number): boolean => isNil(value) || value === '';

const InputDropdown: React.FC<Props> = ({
  label,
  helperText,
  error,
  fullWidth = true,
  options,
  disabled,
  multiple,
  clearable,
  ...rest
}) => {
  const onClear = () => {
    if (rest.onChange) rest.onChange('' as unknown as SelectChangeEvent);
  };

  const { classes } = useStyles({ clearable });
  return (
    <FormControl disabled={disabled} error={!!error} variant='outlined' fullWidth={fullWidth}>
      <InputLabel disabled={disabled} id={`select_label${label}`}>
        {label}
      </InputLabel>
      <div className={classes.selectWrapper}>
        <Select
          className={classes.select}
          multiple={multiple}
          disabled={disabled || !options}
          labelId={`select_label${label}`}
          label={label}
          {...rest}
        >
          {options &&
            options.map(({ value, label: optionLabel }) => (
              <MenuItem key={value} value={value}>
                {optionLabel}
              </MenuItem>
            ))}
        </Select>
        {clearable && (
          <IconButton size='small' onClick={onClear} disabled={disabled || noValue(rest.value)}>
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </div>
      <InputFooter helperText={helperText} error={error} />
    </FormControl>
  );
};

export default InputDropdown;
