import React from 'react';

import sanitizeHtml from 'sanitize-html';

const getLabelForTermsAgreement = (label: string, url: string): JSX.Element => {
  const matches = label.match(/\[(.*)]/);
  if (!matches) return <span>{label}</span>;
  const [textToReplace, linkLabel] = matches;
  const link = `<a target="_blank" href="${url}">${linkLabel}</a>`;
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(label.replace(textToReplace, link)) }} />;
};

export default getLabelForTermsAgreement;
