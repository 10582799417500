import React from 'react';

import { Drawer, Divider } from '@mui/material';

import MenuButton from 'components/UI/molecules/MenuButton/MenuButton';
import useMenuContent from 'hooks/useMenuContent/useMenuContent';
import useLayoutStore from 'storages/layoutStorage';

import useStyles from './MainMenuDesktop.styles';

const MainMenuDesktop: React.FC = () => {
  const { isOpen } = useLayoutStore();
  const menuContent = useMenuContent();

  const { classes } = useStyles({ isOpen });
  return (
    <Drawer className={classes.drawer} classes={{ paper: classes.drawerInner }} variant='permanent' open={isOpen}>
      {menuContent.map(({ label, to, icon, action, endOfSection, cy }) => (
        <>
          <MenuButton cy={cy} key={label} onClick={action} to={to} icon={icon}>
            {label}
          </MenuButton>
          {endOfSection && <Divider key={`${label}-divider`} />}
        </>
      ))}
    </Drawer>
  );
};

export default MainMenuDesktop;
