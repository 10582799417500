import React, { useMemo } from 'react';

import { InputProps } from '@mui/material';
import { Control, Controller, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputDropdown from 'components/UI/molecules/InputDropdown/InputDropdown';
import validationMessages from 'translations/common/validation.mjs';

export type Option = {
  value: string | number;
  label: string;
};

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  options: Option[];
  helperText?: string;
  required?: boolean;
  defaultValue?: string;
  rules?: ValidationRule<any>;
  inputProps?: InputProps;
  multiple?: boolean;
};

const FormInputDropdown: React.FC<Props> = ({
  control,
  label,
  helperText,
  name,
  defaultValue = '',
  inputProps = {},
  rules = {},
  required = false,
  options,
  multiple,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_field) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rulesProp}
      render={({ field, fieldState }) => {
        // for multiple choices...
        const value = multiple && !Array.isArray(field.value) ? [field.value].filter(v => !!v) : field.value;
        return (
          <InputDropdown
            {...inputProps}
            {...field}
            options={options}
            error={fieldState.error?.message}
            label={label}
            helperText={helperText}
            multiple={multiple}
            value={value}
          />
        );
      }}
    />
  );
};

export default FormInputDropdown;
