import React from 'react';

import { AppBar } from '@mui/material';

import AppBarContent from 'components/UI/molecules/AppBarContent/AppBarContent';

type Props = {
  authorized: boolean;
};

const AppTopBar: React.FC<Props> = ({ authorized }) => (
  <AppBar>
    <AppBarContent authorized={authorized} />
  </AppBar>
);

export default AppTopBar;
