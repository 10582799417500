const patientMessages = {
  cardTitle: [ 'patient>>cardTitle', 'Dane profilu' ],
  addNew: [ 'patient>>addNew', 'Dodaj pacjenta' ],
  age: [ 'patient>>age', '{{age}} lat' ],
  fields: {
    firstName: [ 'patient>>fields>>firstName', 'Imię' ],
    lastName: [ 'patient>>fields>>lastName', 'Nazwisko' ],
    email: [ 'patient>>fields>>email', 'Adres email' ],
    pesel: [ 'patient>>fields>>pesel', 'PESEL' ],
    gender: [ 'patient>>fields>>gender', 'Płeć' ],
    dateOfBirth: [ 'patient>>fields>>dateOfBirth', 'Data urodzenia' ],
    address: [ 'patient>>fields>>address', 'Adres' ],
    foreignDocument: [ 'patient>>fields>>foreignDocument', 'Nie mam numeru pesel' ],
    foreignDocumentType: [ 'patient>>fields>>foreignDocumentType', 'Typ dokumentu' ],
    foreignDocumentNumber: [ 'patient>>fields>>foreignDocumentNumber', 'Numer dokumentu' ],
    foreignDocumentCountry: [ 'patient>>fields>>foreignDocumentCountry', 'Kraj wydania dokumentu' ],
  },
  dialog: {
    addPatientTitle: [ 'patient>>dialog>>addPatientTitle', 'Dodaj pacjenta' ],
    editPatientTitle: [ 'patient>>dialog>>editPatientTitle', 'Edytuj pacjenta' ],
    actions: {
      save: [ 'patient>>dialog>>actions>>save', 'Zapisz' ],
      saveGoDashboard: [ 'patient>>dialog>>actions>>saveGoDashboard', 'Zapisz i przejdz na stronę główną' ],
      saveGoToPolicies: [ 'patient>>dialog>>actions>>saveGoToPolicies', 'Zapisz i zobacz Twoje polisy' ],
    }
  },
  documentTypes: {
    id: [ 'patient>>documentTypes>>id', 'Identyfikator osób w państwach UE i strefy Shengen' ],
    idCard: [ 'patient>>documentTypes>>idCard', 'Numer dowodu osobistego w państwach UE i strefy Shengen' ],
    drivingLicence: [ 'patient>>documentTypes>>drivingLicence', 'Numer prawa jazdy w państwach UE i strefy Shengen' ],
    passport: [ 'patient>>documentTypes>>passport', 'Paszport' ],
  },
  otherDocumentNumber: ['patient>>otherDocumentNumber', 'Numer dokumentu'],
}

export default patientMessages
