import React, { useEffect, useRef } from 'react';

import { Divider, Drawer } from '@mui/material';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import MenuButton from 'components/UI/molecules/MenuButton/MenuButton';
import isIOS from 'helpers/isIOS/isIOS';
import useMenuContent from 'hooks/useMenuContent/useMenuContent';
import useLayoutStore from 'storages/layoutStorage';

import useStyles from './MainMenuMobile.styles';

const MainMenuMobile: React.FC = () => {
  const bodyScrollLockElement = useRef<HTMLDivElement>(null);
  const { isOpen, toggleOpen } = useLayoutStore();
  const menuContent = useMenuContent();

  useEffect(() => {
    if (!isIOS()) {
      if (isOpen) disableBodyScroll(bodyScrollLockElement.current as Element);
      else enableBodyScroll(bodyScrollLockElement.current as Element);
    }
    return () => enableBodyScroll(bodyScrollLockElement.current as Element);
  }, [isOpen]);

  const { classes } = useStyles({ isOpen });
  return (
    <Drawer
      ref={bodyScrollLockElement}
      anchor='top'
      classes={{
        paper: classes.drawerPaper,
      }}
      open={isOpen}
      variant='persistent'
    >
      <div className={classes.container}>
        {menuContent.map(({ label, to, icon, action, endOfSection, cy }) => {
          const onClick = () => {
            toggleOpen();
            if (action) action();
          };
          return (
            <>
              <MenuButton cy={cy} key={label} onClick={onClick} to={to} icon={icon}>
                {label}
              </MenuButton>
              {endOfSection && <Divider key={`${label}-divider`} />}
            </>
          );
        })}
      </div>
    </Drawer>
  );
};

export default MainMenuMobile;
