const resetPasswordMessages = {
  header: [ 'resetPassword>>header', 'Nie pamiętam hasła' ],
  header_form: [ 'resetPassword>>header_form', 'Zmiana hasła' ],
  footer: {
    login_link: [ 'resetPassword>>footer>>login_link', 'Powrót do logowania' ],
  },
  reset_successful: [ 'resetPassword>>reset_successful', 'Twoje hasło zostało zrestartowane! Ustaw nowe hasło klikając w link, który wysłaliśmy na Twój adres e-mail.' ],
  reset_form_successful: [ 'resetPassword>>reset_form_successful', 'Twoje hasło zostało ustawione! Zaloguj się na swoje konto używając nowego hasła.' ],
  instruction: [ 'resetPassword>>instruction', 'Podaj poniżej adres e-mail lub identyfikator użytkownika. Na adres powiązany z kontem wyślemy link do ustawienia nowego hasła.' ],
}

export default resetPasswordMessages
