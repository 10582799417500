import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  avatar: {
    width: '60px',
    flexShrink: 0,
  },
}));

export default useStyles;
