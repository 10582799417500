const safeJSONParse = (stringToParse: string): any | null => {
  try {
    return JSON.parse(stringToParse);
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') console.error('Unable to parse string: ', stringToParse);
    return null;
  }
};

export default safeJSONParse;
