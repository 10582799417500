import React from 'react';

import { Container } from '@mui/material';

import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import ReportsCardFull from 'components/UI/organisms/ReportsCardFull/ReportsCardFull';
import { BasePageProps } from 'constants/_types/BasePageProps';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ReportPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);
  return (
    <MainContentWrapper>
      <Container maxWidth='sm'>
        <ReportsCardFull />
      </Container>
    </MainContentWrapper>
  );
};

export default ReportPage;
