import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ width: number; color: string }>()((theme, { width, color }) => ({
  root: {
    width: '50px',
    height: '8px',
    position: 'relative',
    background: theme.palette.grey[300],
    borderRadius: '100px',
    overflow: 'hidden',
  },
  fill: {
    background: color,
    position: 'absolute',
    height: '100%',
    width: `${width}%`,
  },
}));

export default useStyles;
