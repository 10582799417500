import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const useStyles = makeStyles()(theme => ({
  root: {
    background: theme.palette.grey[700],
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: LAYOUT_SIZES.HEADER.lg,
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.grey[50],
    },
  },
  tile: {
    margin: theme.spacing(8),
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(4),
    maxWidth: '720px',
    width: '100%',
    background: theme.palette.grey[50],
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  logo: {
    margin: '0 auto',
    width: '150px',
  },

  footer: {
    maxWidth: '100%',
  },
}));

export default useStyles;
