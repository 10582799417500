import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    whiteSpace: 'pre-line',
  },
  label: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
