const languageMessages = {
  simple_names: {
    en: [ 'language>>simple_names>>en', 'Angielski' ],
    pl: [ 'language>>simple_names>>pl', 'Polski' ],
    es: [ 'language>>simple_names>>es', 'Hiszpański' ],
  },
  language: [ 'language>>language', 'Język' ],
  changeLanguage: [ 'language>>changeLanguage', 'Zmień język' ],
};

export default languageMessages;
