import axios from 'axios';

import { DefaultApiResponse } from './_types';

const endpoints = {
  login: 'api/v1/auth/jwt/create',
  refresh: 'api/v1/auth/jwt/refresh',
  twoFA: '/api/v1/auth/jwt/2fa',
  twoFAResend: '/api/v1/auth/jwt/2fa-resend',
};

type LoginResponse = {
  access: string | null;
  refresh: string | null;
  two_fa: string | null;
};
type LoginQuery = (data: { login_identifier: string; password: string; recaptcha: string }) => Promise<DefaultApiResponse<LoginResponse>>;

type RefreshResponse = {
  access: string;
  refresh: string;
};
type RefreshQuery = (data: { refresh: string }) => Promise<DefaultApiResponse<RefreshResponse>>;

type TwoFAResponse = {
  access?: string;
  refresh?: string;
  non_field_errors?: 'invalid_data' | 'exceeded_retries' | 'invalid_code';
};
type TwoFAQuery = (data: { sms_code: string; token: string; recaptcha: string }) => Promise<DefaultApiResponse<TwoFAResponse>>;

type TwoFAResendQuery = (data: { token: string; recaptcha: string }) => Promise<DefaultApiResponse<unknown>>;

const auth = {
  login: (): LoginQuery => data => axios.post<LoginQuery, DefaultApiResponse<LoginResponse>>(endpoints.login, data),
  refresh: (): RefreshQuery => data => axios.post<RefreshQuery, DefaultApiResponse<RefreshResponse>>(endpoints.login, data),
  twoFA: (): TwoFAQuery => data => axios.post<TwoFAQuery, DefaultApiResponse<TwoFAResponse>>(endpoints.twoFA, data),
  twoFAResend: (): TwoFAResendQuery => data => axios.post<TwoFAResendQuery, DefaultApiResponse<unknown>>(endpoints.twoFAResend, data),
};

export { endpoints as authEndpoints };

export default auth;
