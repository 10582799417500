import React from 'react';

import { Grid } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';

import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import useDocumentsAgreementsQuery from 'hooks/useDocumentsAgreementsQuery/useDocumentsAgreementsQuery';
import getLabelForTermsAgreement from 'services/getLabelForTermsAgreement/getLabelForTermsAgreement';

import { PatientFormInput } from '../PatientDialog';

type Props = {
  form: UseFormReturn<PatientFormInput>;
};

const PatientFormTerms: React.FC<Props> = ({ form }) => {
  const { documentsAgreements } = useDocumentsAgreementsQuery(['patient_profile'], { staleTime: 60 * 60 * 1000 });
  return (
    <Grid item>
      {documentsAgreements?.patient_profile &&
        documentsAgreements.patient_profile.map(({ id, display_text, file, is_required }) => (
          <FormInputCheckbox
            key={id}
            control={form.control}
            required={is_required}
            label={getLabelForTermsAgreement(display_text, file)}
            name={`terms.${id}`}
          />
        ))}
    </Grid>
  );
};

export default PatientFormTerms;
