import React from 'react';

import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import NotFoundContent from 'components/UI/organisms/NotFoundContent/NotFoundContent';

const NotFoundPage: React.FC = () => (
  <MainContentWrapper>
    <NotFoundContent />
  </MainContentWrapper>
);

export default NotFoundPage;
