import { makeStyles } from 'tss-react/mui';

const ICON_SIZE = 32;
const ICON_SIZE_SM = 28;

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: `${ICON_SIZE}px auto ${ICON_SIZE}px`,
    gridGap: theme.spacing(2),
    justifyItems: 'center',
    padding: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(1.5),
      gridTemplateColumns: `${ICON_SIZE_SM}px auto ${ICON_SIZE_SM}px`,
      padding: theme.spacing(3, 2),
    },
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.15rem',
    },
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    [theme.breakpoints.down('sm')]: {
      width: ICON_SIZE_SM,
      height: ICON_SIZE_SM,
    },
  },

  editIcon: {
    marginTop: `-${theme.spacing(1)}`,
    marginLeft: `-${theme.spacing(1)}`,
  },
}));

export default useStyles;
