import React, { useCallback, useEffect, useState } from 'react';

import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import reports from 'api/reports';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import { SymptomCheckerInterviewData } from 'constants/_types/SymptomCheckerInterviewData';
import { SymptomCheckerMessage } from 'constants/_types/SymptomCheckerMessage';
import PATHS from 'constants/router/PATHS';
import removeTrailingSlash from 'helpers/removeTrailngSlash/removeTrailingSlash';
import safeJSONParse from 'helpers/safeJSONParse/safeJSONParse';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import symptomCheckerReportsMessages from 'translations/specific/symptomCheckerReports.mjs';

import useStyles from './SymptomChecker.styles';

type Props = {
  src: string;
};

const SymptomChecker: React.FC<Props> = ({ src }) => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const { patient } = usePatientContext();
  const { enqueueSnackbar } = useSnackbar();
  const { brandInfo } = useBrandContext();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const navigate = useNavigate();
  const [interviewData, setInterviewData] = useState<SymptomCheckerInterviewData | null>(null);
  const [reportId, setReportId] = useState<number | null>(null);

  const saveReport = useMutation('Save report', reports.saveReport(), {
    onSuccess: ({ data }) => {
      setReportId(data.id);
      enqueueSnackbar(t(symptomCheckerReportsMessages.created), { variant: 'success' });
    },
  });

  const updateReport = useMutation(`Update report ${reportId}`, reports.updateReport(reportId as number), {
    onSuccess: () => {
      enqueueSnackbar(t(symptomCheckerReportsMessages.updated), { variant: 'success' });
    },
  });

  const onMessage = useCallback(
    async ({ data, origin }: MessageEvent) => {
      if (
        process.env.REACT_APP_SYMPTOM_CHECKER_URL_BASE &&
        removeTrailingSlash(process.env.REACT_APP_SYMPTOM_CHECKER_URL_BASE) === removeTrailingSlash(origin)
      ) {
        const message = safeJSONParse(data);
        if (!message) return;
        if (message.data.triageLevel && message.data.triageLevel !== interviewData?.triageLevel) {
          await showConfirmationDialog({
            title: t(get(symptomCheckerReportsMessages.triageLevelsDescriptions, `${message.data.triageLevel}.${languageCode}`, ''), ''),
            body: get(brandInfo?.brand.symptom_checker_triage, `${message.data.triageLevel}.${languageCode}`, ''),
            infoOnly: true,
          });
        }
        if (message.action === SymptomCheckerMessage.INTERVIEW_FINISHED) {
          setInterviewData(message.data);
          if (reportId) {
            updateReport.mutate({
              data: message.data,
              patient: patient.id,
            });
          } else {
            saveReport.mutate({
              data: message.data,
              is_hidden: false,
              is_medical_record: true,
              patient: patient.id,
            });
          }
        }
        if (message.action === SymptomCheckerMessage.DISCUSS_WITH_DOCTOR) {
          navigate(PATHS.ROOT, { state: { interviewData, reportId, action: SymptomCheckerMessage.DISCUSS_WITH_DOCTOR } });
        }
        if (message.action === SymptomCheckerMessage.SCHEDULE_APPOINTMENT) {
          navigate(PATHS.ROOT, { state: { interviewData, reportId, action: SymptomCheckerMessage.SCHEDULE_APPOINTMENT } });
        }
      }
    },
    [interviewData, reportId],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  const { classes } = useStyles();

  return <iframe title='Symptom checker' className={classes.iframe} src={src} />;
};

export default SymptomChecker;
