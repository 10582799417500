import React, { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import reports from 'api/reports';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import GeneralErrorContent from 'components/root/GeneralError/_components/GeneralErrorContent';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import SymptomCheckerReportWeb from 'components/UI/organisms/SymptomCheckerReportWeb/SymptomCheckerReportWeb';
import { BasePageProps } from 'constants/_types/BasePageProps';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import { resolveTriageColor, resolveTriageIcon } from 'services/_dictionaryResolvers/resolveTriage/resolveTriage';
import symptomCheckerReportDataParser from 'services/symptomCheckerReportDataParser/symptomCheckerReportDataParser';

const ReportSinglePage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);
  const { id } = useParams() as unknown as { id: number };
  const { brandInfo } = useBrandContext();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const languageCode = useLanguageCode();

  const { data, isLoading, isError } = useQuery(['Get Report', id], reports.getReport(id), { refetchOnWindowFocus: false });

  const parsedData = useMemo(() => {
    if (data) {
      const rawData = data.data;
      return {
        ...symptomCheckerReportDataParser(t, rawData),
        triageBrandText: get(brandInfo?.brand.symptom_checker_triage, `${rawData.data.triageLevel}.${languageCode}`, ''),
        triageStyling: {
          color: resolveTriageColor(theme, rawData.data.triageLevel),
          icon: resolveTriageIcon(rawData.data.triageLevel),
        },
        isHidden: rawData.is_hidden,
        id: rawData.id,
        isMedicalRecord: rawData.is_medical_record,
      };
    }
    return null;
  }, [data, i18n, languageCode, theme]);

  if (isLoading) return <LoaderOverlay inner />;
  if (isError || !parsedData) return <GeneralErrorContent standAlone />;
  return (
    <MainContentWrapper>
      <SymptomCheckerReportWeb {...parsedData} queryKey={['Get Report', id]} />
    </MainContentWrapper>
  );
};

export default ReportSinglePage;
