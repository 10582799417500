import React from 'react';

import { Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';

import generalMessages from 'translations/common/general.mjs';

import useStyles from './GeneralErrorContent.styles';

type Props = {
  standAlone?: boolean;
};
const GeneralErrorContent: React.FC<Props> = ({ standAlone = false }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ standAlone });
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography data-testid='errorboundary' component='h1' variant='h2'>
          {t(generalMessages.generalError)}
        </Typography>
      </Card>
    </div>
  );
};

export default GeneralErrorContent;
