import React, { useCallback } from 'react';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { ButtonBase, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { useNavigate, generatePath } from 'react-router-dom';

import { SymptomCheckerTriageLevels } from 'constants/_types/SymptomCheckerTriageLevels';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import { INTERNAL_PATHS } from 'constants/router/PATHS';
import trimString from 'helpers/trimString/trimString';
import { resolveTriageColor } from 'services/_dictionaryResolvers/resolveTriage/resolveTriage';

import useStyles from './ReportsListLabel.styles';

type Props = {
  id: number;
  triageLevel: SymptomCheckerTriageLevels;
  createdAt: string;
  conditionName: string;
};

const ReportsListLabel: React.FC<Props> = ({ id, triageLevel, createdAt, conditionName }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate(generatePath(`/${INTERNAL_PATHS.REPORT_SINGLE}`, { id: id.toString() }));
  }, [navigate]);

  const triageColor = resolveTriageColor(theme, triageLevel);

  const { classes } = useStyles();
  return (
    <ButtonBase onClick={onButtonClick} className={classes.link}>
      <InsertDriveFileOutlinedIcon
        className={classes.fileIcon}
        style={{
          backgroundColor: triageColor.main,
          color: triageColor.contrastText,
        }}
      />
      <div className={classes.label}>
        <Typography variant='caption'>{format(new Date(createdAt), DATE_FORMATS.DISPLAY)}</Typography>
        <Typography align='left' variant='body2'>
          {trimString(40, conditionName)}
        </Typography>
      </div>
    </ButtonBase>
  );
};

export default ReportsListLabel;
