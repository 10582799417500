import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ clearable?: boolean }>()((theme, { clearable }) => ({
  selectWrapper: {
    display: 'grid',
    gridTemplateColumns: clearable ? '1fr 43px' : '1fr',
    gridGap: theme.spacing(2),
  },
  select: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre',
  },
}));

export default useStyles;
