import React from 'react';

import { cloneDeep } from 'lodash';

import LoginPage from 'components/pages/_auth/LoginPage/LoginPage';
import RegisterPage from 'components/pages/_auth/RegisterPage/RegisterPage';
import RegisterSuccessPage from 'components/pages/_auth/RegisterSuccessPage/RegisterSuccessPage';
import ResetPasswordFormPage from 'components/pages/_auth/ResetPasswordFormPage/ResetPasswordFormPage';
import ResetPasswordFormSuccessPage from 'components/pages/_auth/ResetPasswordFormSuccessPage/ResetPasswordFormSuccessPage';
import ResetPasswordRequestPage from 'components/pages/_auth/ResetPasswordRequestPage/ResetPasswordRequestPage';
import ResetPasswordSuccessPage from 'components/pages/_auth/ResetPasswordSuccessPage/ResetPasswordSuccessPage';
import MyProfileGeneralPage from 'components/pages/_my-profile/MyProfileGeneralPage/MyProfileGeneralPage';
import MyProfilePolicySubscriptionsPage from 'components/pages/_my-profile/MyProfilePolicySubscriptionsPage/MyProfilePolicySubscriptionsPage';
import DashboardPage from 'components/pages/DashboardPage/DashboardPage';
import ReportPage from 'components/pages/ReportPage/ReportPage';
import ReportSinglePage from 'components/pages/ReportSinglePage/ReportSinglePage';
import SymptomCheckerPage from 'components/pages/SymptomCheckerPage/SymptomCheckerPage';
import MyProfilePage from 'components/root/MyProfilePage/MyProfilePage';
import removeTrailingSlash from 'helpers/removeTrailngSlash/removeTrailingSlash';

import { AUTH_PATHS, INTERNAL_PATHS, MY_PROFILE_PATHS } from './PATHS';

export type Route = {
  path: string;
  element: React.FC<{
    routeConfig?: Route & {
      subRoutes?: Route[];
    };
  }>;
  index?: boolean;
  scrollRestoration?: boolean;
};

export type NestedRoute = Route & {
  subRoutes?: Route[];
};

export type Routes = {
  allRoutes: Route[];
  authRoutes: Route[];
  internalRoutes: Route[];
};

const authRoutes: NestedRoute[] = [
  { path: AUTH_PATHS.AUTH_LOGIN, element: LoginPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_REGISTER, element: RegisterPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_REGISTER_SUCCESS, element: RegisterSuccessPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_REQUEST, element: ResetPasswordRequestPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_FORM, element: ResetPasswordFormPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_SUCCESS, element: ResetPasswordSuccessPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_FORM_SUCCESS, element: ResetPasswordFormSuccessPage, scrollRestoration: false },
];

const internalRoutes: NestedRoute[] = [
  { path: INTERNAL_PATHS.ROOT, element: DashboardPage, scrollRestoration: false },
  { path: INTERNAL_PATHS.SYMPTOM_CHECKER, element: SymptomCheckerPage, scrollRestoration: false },
  { path: INTERNAL_PATHS.REPORTS, element: ReportPage, scrollRestoration: false },
  { path: INTERNAL_PATHS.REPORT_SINGLE, element: ReportSinglePage, scrollRestoration: false },
  {
    path: INTERNAL_PATHS.MY_PROFILE,
    element: MyProfilePage,
    scrollRestoration: false,
    subRoutes: [
      { path: MY_PROFILE_PATHS.MY_PROFILE_GENERAL, element: MyProfileGeneralPage, scrollRestoration: false, index: true },
      { path: MY_PROFILE_PATHS.MY_PROFILE_POLICY_SUBSCRIPTIONS, element: MyProfilePolicySubscriptionsPage, scrollRestoration: false },
    ],
  },
];

const allRoutes: Route[] = [];
const collectAllRoutes = (routesArr: NestedRoute[], prefix = ''): void => {
  routesArr.forEach(route => {
    if (route.subRoutes) {
      allRoutes.push(route);
      collectAllRoutes(route.subRoutes, route.path);
      return;
    }
    // eslint-disable-next-line no-param-reassign
    route.path = `${prefix}/${removeTrailingSlash(route.path)}`;
    allRoutes.push(route);
  });
};
collectAllRoutes(cloneDeep([...authRoutes, ...internalRoutes]));

const routes: Routes = { allRoutes, authRoutes, internalRoutes };

export default routes;
