import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AddressUnassigned, Patient, PatientUnassigned, PersonalDataUnassigned } from 'api/_types';
import addressData from 'api/addressData';
import patients from 'api/patients';
import personalData from 'api/personalData';
import users from 'api/users';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import { GlobalDialog, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import { Gender } from 'constants/_types/Gender';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import PATHS from 'constants/router/PATHS';
import comparePhoneNumbers from 'helpers/comparePhoneNumbers/comparePhoneNumbers';
import useDocumentAgreementsUpdate from 'hooks/useDocumentAgreementsUpdate/useDocumentAgreementsUpdate';
import snackbarMessages from 'translations/common/snackbar.mjs';
import patientMessages from 'translations/specific/patient.mjs';

import PatientFormAddressData from './_components/PatientFormAddressData';
import PatientFormBaseData from './_components/PatientFormBaseData';
import PatientFormPersonalData from './_components/PatientFormPersonalData';
import PatientFormTerms from './_components/PatientFormTerms';
import useStyles from './PatientDialog.styles';

type Props = {
  close: () => void;
  refreshData: (config: { action: 'create' | 'update' }) => void;
  isOpen: boolean;
  initialData?: Patient;
  isEdit: boolean;
};

export type PatientFormInput = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  pesel: string;
  street_name: string;
  building_number: string;
  apartment_number: string;
  postcode: string;
  city: string;
  country: string;
  gender: Gender | '';
  dob: string | Date;
  date_of_birth: string | Date;
  foreign_document: boolean;
  foreign_document_type?: number;
  foreign_document_number?: string;
  foreign_document_country?: string;
  terms: {
    [key: string]: boolean;
  };
};

const preparePersonalDataForUpdate = (rawData: PatientFormInput): PersonalDataUnassigned => {
  const result: PersonalDataUnassigned = {
    title: rawData.gender === 'm' ? 'mr' : 'mrs',
    first_name: rawData.first_name,
    last_name: rawData.last_name,
    email: rawData.email,
    phone_number: rawData.phone_number,
    gender: rawData.gender,
  };
  if (rawData.foreign_document) {
    result.date_of_birth = format(new Date(rawData.date_of_birth), DATE_FORMATS.API_DATE);
  }
  return result;
};

const prepareAddressDataForUpdate = (rawData: PatientFormInput): AddressUnassigned => ({
  street_name: rawData.street_name,
  building_number: rawData.building_number,
  apartment_number: rawData.apartment_number,
  postcode: rawData.postcode,
  city: rawData.city,
  country: rawData.country,
});

const prepareDataForCreate = (rawData: PatientFormInput): PatientUnassigned => {
  const result: PatientUnassigned = {
    pesel: rawData.pesel,
    foreign_document: rawData.foreign_document,
    personal_data: {
      ...preparePersonalDataForUpdate(rawData),
      main_address: {
        street_name: rawData.street_name,
        building_number: rawData.building_number,
        apartment_number: rawData.apartment_number,
        postcode: rawData.postcode,
        city: rawData.city,
        country: rawData.country,
      },
    },
  };
  if (result.foreign_document) {
    result.foreign_document_type = rawData.foreign_document_type;
    result.foreign_document_number = rawData.foreign_document_number;
    result.foreign_document_country = rawData.foreign_document_country;
  }
  return result;
};

const PatientDialog: React.FC<Props> = ({ isOpen, close, initialData, isEdit, refreshData }) => {
  const { t } = useTranslation();
  const { userInfo } = useAuthContext();
  const { patient } = usePatientContext();
  const { brandInfo } = useBrandContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { updateDocumentAgreements } = useDocumentAgreementsUpdate();
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();

  const form = useForm<PatientFormInput>({
    defaultValues: isEdit
      ? {
          first_name: initialData?.personal_data.first_name,
          last_name: initialData?.personal_data.last_name,
          email: initialData?.personal_data.email,
          phone_number: userInfo?.phoneNumber || '',
          pesel: initialData?.pesel,
          street_name: initialData?.personal_data.main_address.street_name,
          building_number: initialData?.personal_data.main_address.building_number,
          apartment_number: initialData?.personal_data.main_address.apartment_number,
          postcode: initialData?.personal_data.main_address.postcode,
          city: initialData?.personal_data.main_address.city,
          country: initialData?.personal_data.main_address.country,
          gender: initialData?.personal_data.gender,
          date_of_birth: initialData?.personal_data?.date_of_birth && new Date(initialData.personal_data.date_of_birth),
          foreign_document: initialData?.foreign_document,
          foreign_document_type: initialData?.foreign_document_type,
          foreign_document_number: initialData?.foreign_document_number,
          foreign_document_country: initialData?.foreign_document_country,
          terms: {},
        }
      : {
          email: userInfo.email || '',
          phone_number: userInfo.phoneNumber || '',
          foreign_document: false,
          terms: {},
        },
  });

  const createMutation = useMutation('Create patient', patients.createPatient(), {
    onSuccess: () => {
      refreshData({ action: 'create' });
    },
    onError: () => {
      enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    },
  });

  const updatePersonalDataMutation = useMutation(
    'Update patient personal data',
    personalData.patchPersonalData(patient?.personal_data.id),
    {
      onError: () => {
        enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
      },
    },
  );

  const updateAddressDataMutation = useMutation('Update patient address data', addressData.patchAddressData(patient?.personal_data.id), {
    onError: () => {
      enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    },
  });

  const updateNumberMutation = useMutation('Update number mutation', users.phoneUpdate(), {
    onError: () => {
      enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    },
  });

  const revertNumberMutation = useMutation('Revert number mutation', users.phoneUpdateRevert(), {
    onSuccess: () => {
      if (userInfo.phoneNumber) form.setValue('phone_number', userInfo.phoneNumber);
    },
    onError: () => {
      enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    },
  });

  const goToDashboard = () => {
    navigate(PATHS.ROOT);
  };

  const onSubmit = async (data: PatientFormInput, callback?: () => void) => {
    const isNumberChanged = userInfo.phoneNumber && !comparePhoneNumbers(userInfo.phoneNumber, data.phone_number);
    if (isNumberChanged) {
      if (brandInfo?.config.registration_required_phone_number) {
        try {
          const phoneUpdated = await addToGlobalDialogQueue({
            type: GlobalDialog.VERIFY_MOBILE_NUMBER,
            props: { initialNumber: data.phone_number, cancelable: true },
            withPromise: true,
          });
          if (!phoneUpdated) {
            close();
            return;
          }
        } catch (e: unknown) {
          if (e === 'expired') {
            await revertNumberMutation.mutate();
            return;
          }
        }
      } else {
        await updateNumberMutation.mutate({ phone_number: data.phone_number });
      }
    }
    if (isEdit) {
      await Promise.all([
        updatePersonalDataMutation.mutateAsync(preparePersonalDataForUpdate(data)),
        updateAddressDataMutation.mutateAsync(prepareAddressDataForUpdate(data)),
      ]);
      refreshData({ action: 'update' });
    } else {
      await createMutation.mutateAsync(prepareDataForCreate(data));
      await updateDocumentAgreements(data.terms);
    }
    close();
    if (callback) callback();
  };

  const onButtonClick = (callback?: () => void) => () => {
    form.handleSubmit(data => onSubmit(data, callback))();
  };

  const loading = createMutation.isLoading || updatePersonalDataMutation.isLoading;
  const { classes } = useStyles();
  return (
    <DialogWrapper
      header={t(isEdit ? patientMessages.dialog.editPatientTitle : patientMessages.dialog.addPatientTitle)}
      isOpen={isOpen}
      close={close}
      actions={
        <div className={classes.buttons}>
          <LoadingButton loading={loading} onClick={onButtonClick()} variant='contained'>
            {t(patientMessages.dialog.actions.save)}
          </LoadingButton>
          <Button disabled={loading} onClick={onButtonClick(goToDashboard)} variant='contained'>
            {t(patientMessages.dialog.actions.saveGoDashboard)}
          </Button>
        </div>
      }
    >
      <DialogFormWrapper>
        <Grid container spacing={3}>
          <PatientFormBaseData form={form} />
          <PatientFormPersonalData form={form} isEdit={isEdit} />
          <PatientFormAddressData form={form} />
          {!isEdit && <PatientFormTerms form={form} />}
        </Grid>
      </DialogFormWrapper>
    </DialogWrapper>
  );
};

export default PatientDialog;
