import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  link: {
    width: '100%',
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'auto 1fr',
    justifyItems: 'flex-start',
  },
  label: {
    display: 'grid',
    justifyItems: 'flex-start',
  },
  fileIcon: {
    padding: theme.spacing(0.75),
    height: '2.25rem',
    width: '2.25rem',
    borderRadius: '100%',
  },
}));

export default useStyles;
