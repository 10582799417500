export const AUTH_PREFIX = 'auth';
export const INTERNAL_PREFIX = '';
export const MY_PROFILE_PREFIX = 'my-profile';

interface Paths {
  [key: string]: string;
}

const addPrefix = (paths: Paths, prefix: string): Paths =>
  Object.entries(paths).reduce((acc, [key, value]) => ({ ...acc, [key]: value ? `/${prefix}/${value}` : `/${prefix}` }), {});

export const INTERNAL_PATHS = {
  ROOT: '/',
  MY_PROFILE: MY_PROFILE_PREFIX,
  SYMPTOM_CHECKER: 'symptom-checker',
  REPORTS: 'reports',
  REPORT_SINGLE: 'reports/:id',
};

export const MY_PROFILE_PATHS = {
  MY_PROFILE: '',
  MY_PROFILE_GENERAL: 'general',
  MY_PROFILE_POLICY_SUBSCRIPTIONS: 'policy-subscriptions',
};

export const AUTH_PATHS = {
  AUTH_LOGIN: 'login',
  AUTH_REGISTER: 'register',
  AUTH_REGISTER_SUCCESS: 'register-success',
  AUTH_RESET_PASSWORD_REQUEST: 'reset-password-request',
  AUTH_RESET_PASSWORD_FORM: 'reset-password/:uid/:token',
  AUTH_RESET_PASSWORD_SUCCESS: 'reset-password-request-success',
  AUTH_RESET_PASSWORD_FORM_SUCCESS: 'reset-password-success',
};

const PATHS: Paths = {
  ...INTERNAL_PATHS,
  ...addPrefix(AUTH_PATHS, AUTH_PREFIX),
  ...addPrefix(MY_PROFILE_PATHS, MY_PROFILE_PREFIX),
};

export default PATHS;
