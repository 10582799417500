import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

type StylingProps = {
  hasNavigation: boolean;
  isSideBarOpen: boolean;
  isReducedPadding: boolean;
};

const useStyles = makeStyles<StylingProps>()((theme, { hasNavigation, isSideBarOpen, isReducedPadding }) => ({
  root: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: hasNavigation ? '190px auto' : 'auto',
    gridTemplateRows: `minmax(calc(100vh - ${LAYOUT_SIZES.HEADER.lg}px), auto) auto`,
    background: theme.palette.common.white, // possible conflict with redesign

    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },

  nav: {
    position: 'sticky',
    top: LAYOUT_SIZES.HEADER.lg,
    height: `calc(100vh - ${LAYOUT_SIZES.HEADER.lg}px)`,
    background: theme.palette.background.default,
    padding: theme.spacing(2, 0, 2, 1),
    display: 'grid',
    alignContent: 'flex-start',
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2),
      height: 'auto',
      display: 'flex',
      borderBottom: `thin solid ${theme.palette.grey[200]}`,
      width: `calc(100vw - ${isSideBarOpen ? LAYOUT_SIZES.SIDE_MENU.open : LAYOUT_SIZES.SIDE_MENU.closed}px)`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: isSideBarOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },

  navTitle: {
    padding: theme.spacing(2, 0, 2, 1.5),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
  },

  navLinkContainer: {
    display: 'grid',

    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      gridGap: theme.spacing(0, 3),
      overflowX: 'scroll',
    },
  },

  navLink: {
    color: theme.palette.grey[600],
    padding: theme.spacing(1.5),
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    transition: theme.transitions.create(['color', 'background'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    '&.active': {
      color: theme.palette.primary.main,
      background: theme.palette.grey[100],
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down('md')]: {
      whiteSpace: 'nowrap',
      padding: theme.spacing(1, 1.5),
    },
  },

  messages: {
    display: 'grid',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  main: {
    padding: isReducedPadding ? theme.spacing(1, 3, 3) : theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: isReducedPadding ? theme.spacing(1, 2, 2) : theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },

  footer: {
    gridColumn: hasNavigation ? '2/3' : 'unset',
  },
}));

export default useStyles;
