import React from 'react';

import useStyles from './SimpleFormWrapper.styles';

type Props = {
  children: React.ReactNode;
};

const SimpleFormWrapper: React.FC<Props> = ({ children }) => {
  const { classes } = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default SimpleFormWrapper;
