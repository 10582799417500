import React, { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import users, { RegisterQueryInput } from 'api/users';
import PasswordValidation from 'components/UI/molecules/PasswordValidation/PasswordValidation';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import PATHS from 'constants/router/PATHS';
import useDocumentsAgreementsQuery from 'hooks/useDocumentsAgreementsQuery/useDocumentsAgreementsQuery';
import getLabelForTermsAgreement from 'services/getLabelForTermsAgreement/getLabelForTermsAgreement';
import authMessages from 'translations/common/auth.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import validationMessages from 'translations/common/validation.mjs';
import registerMessages from 'translations/specific/register.mjs';

import useStyles from './RegisterForm.styles';

type RegisterFormInput = {
  email: string;
  password: string;
  re_password: string;
  terms: {
    [key: string]: boolean;
  };
};

const parseData = (data: RegisterFormInput, recaptcha: string): RegisterQueryInput => ({
  email: data.email,
  password: data.password,
  re_password: data.re_password,
  recaptcha,
  documents: Object.entries(data.terms)
    .filter(([_, value]) => value)
    .map(([key]) => Number(key)),
});

const RegisterForm: React.FC = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const { documentsAgreements } = useDocumentsAgreementsQuery(['terms_and_conditions', 'privacy_policy', 'marketing']);
  const { enqueueSnackbar } = useSnackbar();

  const registerMutation = useMutation('Register', users.register(), {
    onSuccess: ({ data }) => {
      const { email } = data;
      if (email) navigate(PATHS.AUTH_REGISTER_SUCCESS, { state: { email } });
    },
    onError: ({ response }) => {
      if (response.data.detail) setApiError(response.data.detail);
      if (response.data.password) setApiError(response.data.password[0]);
      else
        enqueueSnackbar(t(registerMessages.registration_error), {
          variant: 'error',
        });
    },
  });

  const { control, handleSubmit, watch, getValues } = useForm<RegisterFormInput>({ defaultValues: { terms: {} } });
  watch();

  const onSubmit: SubmitHandler<RegisterFormInput> = async data => {
    if (!executeRecaptcha) enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    else {
      const recaptcha = await executeRecaptcha('Register');
      registerMutation.mutate(parseData(data, recaptcha));
    }
  };

  const { classes } = useStyles();
  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h3' component='h1' align='center' marginBottom={2}>
        {t(registerMessages.header)}
      </Typography>
      <SimpleFormWrapper>
        <FormInputText
          rules={{ pattern: { value: /@/, message: t(validationMessages.invalid_email) } }}
          required
          name='email'
          control={control}
          label={t(authMessages.email)}
        />
        <FormInputPassword required name='password' control={control} label={t(authMessages.new_password)} />
        <FormInputPassword required name='re_password' control={control} label={t(authMessages.repeat_new_password)} />
        <PasswordValidation setIsValid={setPasswordValid} password={getValues().password} rePassword={getValues().re_password} />
        <Grid container data-cy='terms_container'>
          {documentsAgreements?.terms_and_conditions &&
            documentsAgreements.terms_and_conditions.map(({ id, is_required, display_text, file }) => (
              <Grid item xs={12}>
                <FormInputCheckbox
                  tiny
                  required={is_required}
                  control={control}
                  name={`terms.${id}`}
                  label={getLabelForTermsAgreement(display_text, file)}
                />
              </Grid>
            ))}
          {documentsAgreements?.privacy_policy &&
            documentsAgreements.privacy_policy.map(({ id, is_required, display_text, file }) => (
              <Grid item xs={12}>
                <FormInputCheckbox
                  tiny
                  required={is_required}
                  control={control}
                  name={`terms.${id}`}
                  label={getLabelForTermsAgreement(display_text, file)}
                />
              </Grid>
            ))}
          {documentsAgreements?.marketing &&
            documentsAgreements.marketing.map(({ id, is_required, display_text, file }) => (
              <Grid item xs={12}>
                <FormInputCheckbox
                  tiny
                  required={is_required}
                  control={control}
                  name={`terms.${id}`}
                  label={getLabelForTermsAgreement(display_text, file)}
                />
              </Grid>
            ))}
        </Grid>
        <Typography align='center' variant='caption' color='error'>
          {apiError}
        </Typography>
        <LoadingButton
          data-cy='register_button'
          variant='contained'
          disabled={!passwordValid}
          loading={registerMutation.isLoading}
          type='submit'
        >
          {t(registerMessages.button_label)}
        </LoadingButton>
      </SimpleFormWrapper>
    </form>
  );
};

export default RegisterForm;
