import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import isProduction from 'helpers/isProduction/isProduction';

type LayoutStore = {
  isOpen: boolean;
  toggleOpen: () => void;
};

const store = persist<LayoutStore>(
  set => ({
    isOpen: false,
    toggleOpen: () => set(state => ({ isOpen: !state.isOpen })),
  }),
  { name: 'layoutStore', getStorage: () => sessionStorage },
);

const useLayoutStore = isProduction() ? create(store) : create(devtools(store));

export default useLayoutStore;
