import React from 'react';

import { useTheme } from '@mui/material/styles';
import { pdf } from '@react-pdf/renderer';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import reports from 'api/reports';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import SymptomCheckerReportPdf from 'components/UI/organisms/SymptomCheckerReportPdf/SymptomCheckerReportPdf';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import { resolveTriageColor } from 'services/_dictionaryResolvers/resolveTriage/resolveTriage';
import symptomCheckerReportDataParser, {
  ReportCommonDataResult,
} from 'services/symptomCheckerReportDataParser/symptomCheckerReportDataParser';

const useGetReportBlob = () => {
  const { t } = useTranslation();
  const { brandInfo } = useBrandContext();
  const langCode = useLanguageCode();
  const theme = useTheme();

  const getReportBlob = async (id: number): Promise<{ blob: Blob; reportData: ReportCommonDataResult }> => {
    const { data: singleReportData } = await reports.getReport(id)();

    const pdfData = symptomCheckerReportDataParser(t, singleReportData);
    const pdfProps = {
      ...pdfData,
      triageBrandText: get(brandInfo?.brand.symptom_checker_triage, `${singleReportData.data.triageLevel}.${langCode}`, ''),
      triageStyling: {
        color: resolveTriageColor(theme, singleReportData.data.triageLevel),
      },
      triageLevel: singleReportData.data.triageLevel,
    };

    return new Promise(resolve => {
      pdf(<SymptomCheckerReportPdf {...pdfProps} />)
        .toBlob()
        .then(blob => resolve({ blob, reportData: pdfData }));
    });
  };

  return getReportBlob;
};

export default useGetReportBlob;
