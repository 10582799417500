const LAYOUT_SIZES = {
  HEADER: {
    lg: 64,
  },
  SIDE_MENU: {
    open: 180,
    closed: 71,
  },
};

export default LAYOUT_SIZES;
