import React from 'react';

import { Theme } from '@mui/material/styles';

import { ReactComponent as ConsultationIcon } from 'assets/icons/triage/consultation.svg';
import { ReactComponent as Consultation24Icon } from 'assets/icons/triage/consultation_24.svg';
import { ReactComponent as EmergencyIcon } from 'assets/icons/triage/emergency.svg';
import { ReactComponent as EmergencyAmbulanceIcon } from 'assets/icons/triage/emergency_ambulance.svg';
import { ReactComponent as SelfCareIcon } from 'assets/icons/triage/self_care.svg';
import { SymptomCheckerTriageLevels } from 'constants/_types/SymptomCheckerTriageLevels';

const resolveTriageColor = (theme: Theme, triage: SymptomCheckerTriageLevels) => {
  const { triage: triageColor } = theme.palette;
  switch (triage) {
    case SymptomCheckerTriageLevels.CONSULTATION:
      return triageColor.consultation;
    case SymptomCheckerTriageLevels.CONSULTATION_24:
      return triageColor.consultation_24;
    case SymptomCheckerTriageLevels.EMERGENCY:
      return triageColor.emergency;
    case SymptomCheckerTriageLevels.SELF_CARE:
      return triageColor.self_care;
    case SymptomCheckerTriageLevels.EMERGENCY_AMBULANCE:
      return triageColor.emergency_ambulance;
    default:
      return theme.palette.primary;
  }
};

type ResolveTriageIcon = (triage: SymptomCheckerTriageLevels) => React.FC;
const resolveTriageIcon: ResolveTriageIcon = triage => {
  switch (triage) {
    case SymptomCheckerTriageLevels.CONSULTATION:
      return ConsultationIcon;
    case SymptomCheckerTriageLevels.CONSULTATION_24:
      return Consultation24Icon;
    case SymptomCheckerTriageLevels.EMERGENCY:
      return EmergencyIcon;
    case SymptomCheckerTriageLevels.SELF_CARE:
      return SelfCareIcon;
    case SymptomCheckerTriageLevels.EMERGENCY_AMBULANCE:
      return EmergencyAmbulanceIcon;
    default:
      return ConsultationIcon;
  }
};

export { resolveTriageColor, resolveTriageIcon };
