import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const useStyles = makeStyles()(theme => ({
  root: {
    background: theme.palette.background.default,
    minHeight: '100vh',
    display: 'flex',
    paddingTop: LAYOUT_SIZES.HEADER.lg,
  },
  content: {
    background: theme.palette.grey[100],
    flexGrow: 1,
  },
}));

export default useStyles;
