import { Interpolation } from '@emotion/styled';
import { Theme } from '@mui/material';

const prepareGlobalStyles = (theme: Theme): Interpolation<Theme> => ({
  // GLOBAL
  html: {
    scrollBehavior: 'smooth',
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  '@media screen and (min-width: 768px)': {
    'a[href*="tel:"]': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  },

  // INPUT NUMBER
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
  },
  'input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },
  'input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },

  'input[type=number]': {
    '-moz-appearance': 'textfield !important',
  },

  // SCROLL BARS
  '@media (hover: hover)': {
    '*': {
      scrollbarColor: 'rgba(180, 180, 180, 0.25) transparent',
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      width: '0.4rem',
      height: '0.4rem',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'rgba(180, 180, 180, 0.25)',
    },
  },

  // reCAPTCHA
  '.grecaptcha-badge': {
    visibility: 'hidden',
  },
});

export default prepareGlobalStyles;
