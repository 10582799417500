import React, { createContext, useState, useContext, useRef, useMemo } from 'react';

import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

import generalMessages from 'translations/common/general.mjs';

type ConfirmationOptions = {
  title?: string;
  body?: string;
  infoOnly?: boolean;
};
type ShowConfirmationDialog = (options: ConfirmationOptions) => Promise<boolean>;

type Props = {
  children: React.ReactNode;
};

type ConfirmationDialogContextType = {
  showConfirmationDialog: ShowConfirmationDialog;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ConfirmationDialogContext = createContext<ConfirmationDialogContextType>(null!);

const { Provider } = ConfirmationDialogContext;

const ConfirmationDialogProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState<ConfirmationOptions | null>();

  const awaitingConfirmation = useRef<any>(null);

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => setConfirmationOptions(null), 150);
  };

  const showConfirmationDialog: ShowConfirmationDialog = options => {
    setOpen(true);
    const defaultConfig = { infoOnly: false };
    setConfirmationOptions({ ...defaultConfig, ...options });
    return new Promise(resolve => {
      awaitingConfirmation.current = { resolve };
    });
  };

  const onCancel = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(false);
    }

    closeModal();
  };

  const onConfirm = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(true);
    }

    closeModal();
  };

  const value = useMemo(() => ({ showConfirmationDialog }), [showConfirmationDialog]);

  return (
    <Provider value={value}>
      {children}
      <Dialog
        fullWidth
        maxWidth='xs'
        open={open}
        onClose={onCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {confirmationOptions?.title && <DialogTitle id='alert-dialog-title'>{confirmationOptions.title}</DialogTitle>}
        {confirmationOptions?.body && (
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{confirmationOptions.body}</DialogContentText>
          </DialogContent>
        )}
        {confirmationOptions?.infoOnly ? (
          <DialogActions>
            <Button onClick={onConfirm} variant='contained' autoFocus>
              {t(generalMessages.ok)}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={onCancel} variant='outlined'>
              {t(generalMessages.no)}
            </Button>
            <Button onClick={onConfirm} variant='contained' autoFocus>
              {t(generalMessages.yes)}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Provider>
  );
};

export const useConfirmationDialogContext = () => useContext(ConfirmationDialogContext);

export default ConfirmationDialogProvider;
