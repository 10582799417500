import React, { useEffect, useState } from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import reports from 'api/reports';
import { ReactComponent as Icon } from 'assets/icons/doctor_files.svg';
import CardWithCustomElements from 'components/UI/molecules/CardWithCustomElements/CardWithCustomElements';
import ReportsListLabel from 'components/UI/molecules/ReportsListLabel/ReportsListLabel';
import ReportsServiceButtons, { ButtonsReportProp } from 'components/UI/molecules/ReportsServiceButtons/ReportsServiceButtons';
import dashboardMessages from 'translations/specific/dashboard.mjs';

import useStyles from './ReportsCardFull.styles';

const PAGE_SIZE = 10;

const ReportsCardFull: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);

  const { status, data, isPreviousData } = useQuery(
    ['Reports list', page],
    reports.getReports({
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * page,
    }),
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (data?.data.next) {
      queryClient.prefetchQuery(
        ['Reports list', page + 1],
        reports.getReports({
          limit: PAGE_SIZE,
          offset: PAGE_SIZE * page,
        }),
      );
    }
  }, [page]);

  const onPrev = () => setPage(prev => Math.max(prev - 1, 0));
  const onNext = () => setPage(prev => (data?.data.next ? prev + 1 : prev));
  const onPageNumber = (pageNumber: number) => {
    if (pageNumber !== page) setPage(pageNumber);
  };

  const pagesCount = data ? Math.ceil(data.data.count / PAGE_SIZE) : 0;

  const { classes } = useStyles();

  const pagination = () => (
    <div className={classes.pagination}>
      <IconButton disabled={status === 'loading' || page === 0} onClick={onPrev}>
        <ChevronLeftOutlinedIcon />
      </IconButton>
      {Array.from(Array(pagesCount).keys()).map(possiblePage => (
        <IconButton className={classes.paginationNumber} onClick={() => onPageNumber(possiblePage)} key={possiblePage}>
          <Typography color={possiblePage === page ? 'primary' : 'default'} fontWeight={possiblePage === page ? 'bold' : 'default'}>
            {possiblePage + 1}
          </Typography>
        </IconButton>
      ))}
      <IconButton disabled={status === 'loading' || isPreviousData || !data?.data.next} onClick={onNext}>
        <ChevronRightOutlinedIcon />
      </IconButton>
    </div>
  );
  return (
    <CardWithCustomElements
      icon={Icon}
      header={t(dashboardMessages.reportsCard.header)}
      customFooter={pagination}
      minHeight={400}
      maxHeight={400}
    >
      {data &&
        data.data.results.map(({ id, triage_level, created_at, condition_name, is_hidden, is_medical_record }) => {
          const buttonsReportProp: ButtonsReportProp = {
            id,
            createdAt: created_at,
            isHidden: is_hidden,
            isMedicalRecord: is_medical_record,
          };
          return (
            <div key={id} className={classes.row}>
              <ReportsListLabel id={id} triageLevel={triage_level} createdAt={created_at} conditionName={condition_name} />
              <ReportsServiceButtons queryKey={['Reports list', page]} report={buttonsReportProp} />
            </div>
          );
        })}
    </CardWithCustomElements>
  );
};

export default ReportsCardFull;
