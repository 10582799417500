import React from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputLabel, OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';

type Props = {
  fullWidth?: boolean;
  label: string;
  name: string;
  helperText?: string;
  error?: string;
};

type State = {
  showPassword: boolean;
};

class InputPassword extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  toggleShowPassword = () => this.setState(prev => ({ showPassword: !prev.showPassword }));

  render() {
    const { label, error, fullWidth = true, helperText, ...field } = this.props;
    const { showPassword } = this.state;
    return (
      <FormControl error={!!error} fullWidth={fullWidth}>
        {label && <InputLabel htmlFor={`password_input_${label}`}>{label}</InputLabel>}
        <OutlinedInput
          id={`password_input_${label}`}
          label={label}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                tabIndex={-1}
                color='primary'
                aria-label='toggle password visibility'
                onClick={this.toggleShowPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ 'data-cy': `${field.name}_input` }}
          {...field}
        />
        <InputFooter name={field.name} error={error} helperText={helperText} />
      </FormControl>
    );
  }
}

export default InputPassword;
