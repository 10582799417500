import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  Grow,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import { useBrandContext } from 'components/context/BrandContext/BrandContext';

import useStyles from './DialogWrapper.styles';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => <Grow ref={ref} {...props} />,
);

type Props = {
  header: string;
  children: React.ReactNode;
  isOpen: boolean;
  close: () => void;
  disableClose?: boolean;
  actions?: React.ReactElement;
  dialogProps?: Omit<DialogProps, 'open'>;
  dialogContentProps?: DialogContentProps;
};

const DialogWrapper: React.FC<Props> = ({
  isOpen,
  close,
  header,
  children,
  actions,
  dialogProps = {},
  dialogContentProps = {},
  disableClose,
}) => {
  const { brandInfo } = useBrandContext();
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { classes, cx } = useStyles({ disableClose });
  const mergedDialogProps = {
    ...dialogProps,
    classes: { paper: dialogProps?.classes?.paper ? cx(dialogProps.classes.paper, classes.paper) : classes.paper },
  };

  return (
    <Dialog fullScreen={downSM} scroll='body' fullWidth maxWidth='md' open={isOpen} TransitionComponent={Transition} {...mergedDialogProps}>
      <div className={classes.dialogInnerWrapper}>
        <DialogTitle>
          {!disableClose && (
            <div className={classes.closeBar}>
              <IconButton size='small' onClick={close}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </div>
          )}
          <div className={classes.logoBar}>
            {brandInfo?.brand.logo_left ? <img className={classes.logo} src={brandInfo.brand.logo_left} alt={brandInfo?.name} /> : <span />}
            {brandInfo?.brand.logo_right ? <img className={classes.logo} src={brandInfo.brand.logo_right} alt='MDT' /> : <span />}
          </div>
          <Typography align='center' variant='h2' component='div'>
            {header}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} {...dialogContentProps}>
          {children}
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </div>
    </Dialog>
  );
};

export default DialogWrapper;
