import { makeStyles } from 'tss-react/mui';

const decreaseColumn = (columns: number, level: number): number => {
  const decreasedColumns = columns - level;
  return decreasedColumns > 0 ? decreasedColumns : 1;
};

const useStyles = makeStyles<{ columns: number }>()((theme, { columns }) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridGap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(${decreaseColumn(columns, 1)}, 1fr)`,
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(${decreaseColumn(columns, 2)}, 1fr)`,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `repeat(${decreaseColumn(columns, 3)}, 1fr)`,
    },
  },
}));

export default useStyles;
