import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ disabled?: boolean; maxHeight?: number | string; minHeight?: number | string }>()(
  (theme, { disabled, maxHeight, minHeight }) => ({
    root: {
      pointerEvents: disabled ? 'none' : 'initial',
      userSelect: 'none',
      opacity: disabled ? 0.5 : 1,
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      borderTop: `thin solid ${theme.palette.grey[300]}`,
      maxHeight: maxHeight || '300px',
      minHeight: minHeight || 'unset',
      overflowY: 'auto',
    },
  }),
);

export default useStyles;
