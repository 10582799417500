import { TranslatableOption } from 'constants/_types/DropownOptions';
import { Gender } from 'constants/_types/Gender';
import generalMessages from 'translations/common/general.mjs';

const genderOptions: TranslatableOption[] = [
  { value: Gender.MALE, labelKey: generalMessages.gender.m },
  { value: Gender.FEMALE, labelKey: generalMessages.gender.f },
];

export default genderOptions;
