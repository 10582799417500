import React from 'react';

import useStyles from './AuthTileFooter.styles';

type Props = {
  children: React.ReactNode;
};

const AuthTileFooter: React.FC<Props> = ({ children }) => {
  const { classes } = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default AuthTileFooter;
