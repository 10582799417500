const registerMessages = {
  header: [ 'register>>header', 'Rejestracja' ],
  button_label: [ 'register>>button_label', 'Zarejestruj' ],
  footer: {
    login_link: [ 'register>>footer>>login_link', 'Zaloguj się' ],
    login_link_label: [ 'register>>footer>>login_link_label', 'Masz już konto?' ]
  },
  registration_successful: [ 'register>>registration_successful', 'Dziękujemy. Twoje konto zostało zarejestrowane. Potwierdź rejestrację klikając w link, który wysłaliśmy na {{email}}.' ],
  registration_error: [ 'register>>registration_error', 'Wystąpił problem z rejestracją (możliwe, że konto na podany adres email już jest zarejestrowane)' ],
  your_email_fallback: [ 'register>>your_email_fallback', 'Twój email' ]
}

export default registerMessages
