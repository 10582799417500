import { DependencyList, useEffect, useRef, Ref } from 'react';

import PubSub from 'pubsub-js';

import { AppEvents } from 'constants/_types/AppEvents';

type UseSubscription = (event: AppEvents, callback: () => void, deps?: DependencyList) => Ref<string>;

// CONSIDER USING INVALIDATE_QUERY BEFORE USING IT

const useSubscription: UseSubscription = (event, callback, deps = []) => {
  const subscriptionsRef = useRef<string | null>(null);

  useEffect(() => {
    subscriptionsRef.current = PubSub.subscribe(event as string, callback);
    return () => {
      if (Array.isArray(subscriptionsRef.current)) {
        subscriptionsRef.current.forEach(PubSub.unsubscribe);
      }
    };
  }, deps);
  return subscriptionsRef;
};

export default useSubscription;
