import React, { createContext, useState, useContext, lazy, Suspense, useMemo, useRef } from 'react';

import { drop } from 'lodash';

import DialogLoader from 'components/UI/organisms/_dialogs/DialogLoader/DialogLoader';
import TermsAgreementDialog from 'components/UI/organisms/_dialogs/TermsAgreementDialog/TermsAgreementDialog';

const VerifyMobileNumberDialog = lazy(() => import('components/UI/organisms/_dialogs/VerifyMobileNumberDialog/VerifyMobileNumberDialog'));
const PassTwoFADialog = lazy(() => import('components/UI/organisms/_dialogs/PassTwoFADialog/PassTwoFADialog'));

export enum GlobalDialog {
  VERIFY_MOBILE_NUMBER,
  PASS_TWO_FA,
  MISSING_AGREEMENTS,
}

type Props = {
  children: React.ReactNode;
};

type Dialog = {
  type: GlobalDialog;
  props?: {
    promise?: { resolve: (value: any) => any; reject: (value: any) => any };
    [key: string]: any;
  };
  withPromise?: boolean;
};

type GlobalDialogsContextType = {
  dialogQueue: Dialog[];
  closeGlobalDialog: () => void;
  addToGlobalDialogQueue: (dialogs: Dialog) => Promise<any> | null;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const GlobalDialogsContext = createContext<GlobalDialogsContextType>(null!);

const hasTokenInProps = (props: any): props is { isOpen: boolean; close: () => void; token: string } => {
  if (props.token) return props;
  return false;
};

const GlobalDialogsContextProvider: React.FC<Props> = ({ children }) => {
  const [dialogQueue, setDialogQueue] = useState<Dialog[]>([]);
  const promise = useRef<any>(null);

  const closeGlobalDialog = () => {
    setDialogQueue(prevDialogQueue => drop(prevDialogQueue));
  };

  const addToGlobalDialogQueue = (dialog: Dialog) => {
    if (dialog.withPromise) {
      return new Promise((resolve, reject) => {
        if (!dialogQueue.find(({ type }) => type === dialog.type)) {
          promise.current = { resolve, reject };
          setDialogQueue(prevDialogQueue => [...prevDialogQueue, dialog]);
        }
      });
    }
    if (!dialogQueue.find(({ type }) => type === dialog.type)) {
      setDialogQueue(prevDialogQueue => [...prevDialogQueue, dialog]);
    }
    return null;
  };

  const flushGlobalDialogQueue = () => {
    setDialogQueue([]);
  };

  const renderDialog = (dialog: Dialog) => {
    if (!dialog) return null;
    const props = {
      close: closeGlobalDialog,
      isOpen: true,
      promise: dialog.withPromise ? promise.current : undefined,
      flushGlobalDialogQueue,
      ...dialog.props,
    };
    switch (dialog.type) {
      case GlobalDialog.VERIFY_MOBILE_NUMBER:
        return <VerifyMobileNumberDialog {...props} />;
      case GlobalDialog.PASS_TWO_FA:
        return hasTokenInProps(props) ? <PassTwoFADialog {...props} /> : null;
      case GlobalDialog.MISSING_AGREEMENTS:
        return <TermsAgreementDialog fillMissing {...props} />;
      default:
        return null;
    }
  };

  const value = useMemo(
    () => ({
      dialogQueue,
      closeGlobalDialog,
      addToGlobalDialogQueue,
      flushGlobalDialogQueue,
    }),
    [dialogQueue, closeGlobalDialog, addToGlobalDialogQueue, flushGlobalDialogQueue],
  );

  return (
    <GlobalDialogsContext.Provider value={value}>
      {children}
      {dialogQueue.length ? <Suspense fallback={<DialogLoader isOpen />}>{renderDialog(dialogQueue[0])}</Suspense> : null}
    </GlobalDialogsContext.Provider>
  );
};

export const useGlobalDialogsContext = () => useContext(GlobalDialogsContext);

export default GlobalDialogsContextProvider;
