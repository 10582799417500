import React from 'react';

import { Container, Typography, Alert, AlertTitle } from '@mui/material';
import Grow from '@mui/material/Grow';
import { NavLink } from 'react-router-dom';

import AppFooter from 'components/UI/organisms/AppFooter/AppFooter';
import useLayoutStore from 'storages/layoutStorage';

import useStyles from './MainContentWrapper.styles';

type Link = {
  label: string;
  to: string;
};

export type Message = {
  content: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  description?: string;
  action?: React.ReactNode;
};

type Props = {
  children: React.ReactNode;
  subNavigationTitle?: string;
  messages?: Message[];
  links?: Link[];
  containerWidth?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  isReducedPadding?: boolean;
};

const MainContentWrapper: React.FC<Props> = ({
  children,
  links,
  messages,
  subNavigationTitle,
  containerWidth = 'xl',
  isReducedPadding = false,
}) => {
  const { isOpen } = useLayoutStore();
  const { classes } = useStyles({ hasNavigation: !!links, isSideBarOpen: isOpen, isReducedPadding });
  return (
    <div className={classes.root}>
      {links && (
        <nav className={classes.nav}>
          <Typography variant='h3' className={classes.navTitle}>
            {subNavigationTitle}
          </Typography>
          <div className={classes.navLinkContainer}>
            {links.map(({ label, to }) => (
              <NavLink className={classes.navLink} to={to} key={label}>
                <Typography variant='body1'>{label}</Typography>
              </NavLink>
            ))}
          </div>
        </nav>
      )}

      <Container maxWidth={containerWidth} component='main' className={classes.main}>
        {messages && (
          <div className={classes.messages}>
            {messages.length
              ? messages.map(({ action, content, description, severity }) => (
                  <Grow key={content} in>
                    <Alert action={action && action} severity={severity}>
                      <AlertTitle>{content}</AlertTitle>
                      {description}
                    </Alert>
                  </Grow>
                ))
              : null}
          </div>
        )}
        {children}
      </Container>
      <footer className={classes.footer}>
        <AppFooter />
      </footer>
    </div>
  );
};

export default MainContentWrapper;
