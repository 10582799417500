import React from 'react';

import { Routes, Route } from 'react-router-dom';

import LoginPage from 'components/pages/_auth/LoginPage/LoginPage';
import DashboardPage from 'components/pages/DashboardPage/DashboardPage';
import NotFoundPage from 'components/pages/NotFoundPage/NotFoundPage';
import AuthPage from 'components/root/AuthPage/AuthPage';
import PrivateRoute from 'components/root/PrivateRoute/PrivateRoute';
import PATHS, { AUTH_PREFIX, INTERNAL_PREFIX } from 'constants/router/PATHS';
import routes, { NestedRoute as RouteType } from 'constants/router/routes';

const resolveConfig = (pathToCheck: string): RouteType | undefined =>
  routes.internalRoutes.find(({ path }) => path === pathToCheck) || routes.authRoutes.find(({ path }) => path === pathToCheck);

const renderer = (routesToRender: RouteType[]) =>
  routesToRender.map(routeConfig => {
    const { path, element: Element, subRoutes } = routeConfig;
    if (subRoutes) {
      const indexRoute = subRoutes.find(({ index }) => index);
      return (
        <Route key={path} path={path} element={<Element routeConfig={routeConfig} />}>
          {indexRoute && <Route index element={<indexRoute.element routeConfig={routeConfig} />} />}
          {renderer(subRoutes)}
        </Route>
      );
    }
    return <Route key={path} path={path} element={<Element routeConfig={routeConfig} />} />;
  });

const Router: React.FC = () => (
  <Routes>
    <Route path={AUTH_PREFIX} element={<AuthPage />}>
      <Route index element={<LoginPage routeConfig={resolveConfig(PATHS.AUTH_LOGIN)} />} />
      {routes.authRoutes.map(routeConfig => {
        const { path, element: Element } = routeConfig;
        return <Route key={path} path={path} element={<Element routeConfig={routeConfig} />} />;
      })}
    </Route>
    <Route path={INTERNAL_PREFIX} element={<PrivateRoute />}>
      <Route index element={<DashboardPage routeConfig={resolveConfig(PATHS.ROOT)} />} />
      {renderer(routes.internalRoutes)}
    </Route>
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);
export default Router;
