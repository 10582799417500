import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Toolbar } from '@mui/material';

import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import useLayoutStore from 'storages/layoutStorage';

import useStyles from './AppBarContent.styles';

type Props = {
  authorized: boolean;
};

const AppBarContent: React.FC<Props> = ({ authorized }) => {
  const { toggleOpen } = useLayoutStore();
  const { brandInfo } = useBrandContext();

  const { classes } = useStyles({ authorized });
  return (
    <Toolbar classes={{ root: classes.toolbarRoot }}>
      {authorized && (
        <IconButton onClick={toggleOpen}>
          <MenuIcon classes={{ root: classes.menuIcon }} />
        </IconButton>
      )}
      <div className={classes.root}>
        <div className={classes.side}>
          {brandInfo?.brand.logo_left && <img className={classes.logo} src={brandInfo.brand.logo_left} alt={brandInfo?.name} />}
        </div>
        <div className={classes.side}>
          {brandInfo?.brand.logo_right && <img className={classes.logo} src={brandInfo.brand.logo_right} alt='MDT' />}
        </div>
      </div>
    </Toolbar>
  );
};

export default AppBarContent;
