import React, { useCallback, useEffect, useMemo } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import languages, { Language } from 'constants/translations/languages';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';

import useStyles from './LanguageChanger.styles';

const LanguageChanger: React.FC = () => {
  const { i18n, t } = useTranslation();
  const languageCode = useLanguageCode();
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, [setOpen]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const changeLanguage = (code: string) => async () => {
    axios.defaults.headers.common['Accept-Language'] = code;
    await i18n.changeLanguage(code);
    handleClose();
  };

  useEffect(() => {
    axios.defaults.headers.common['Accept-Language'] = languageCode;
  }, []);

  const currentLanguage: Language = useMemo(() => {
    const matchedLanguage = languages.find(({ code }) => code === languageCode);
    return matchedLanguage || languages[0];
  }, [languageCode]);

  const { classes } = useStyles();

  const renderFlag = (Flag: React.JSXElementConstructor<any>) => <Flag className={classes.flag} />;
  return (
    <>
      <Button variant='outlined' size='small' onClick={handleToggle} ref={anchorRef} className={classes.expandButton}>
        {renderFlag(currentLanguage.icon)}
        {t(currentLanguage.code)}
        <ArrowDropDownIcon />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement='bottom-end'>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {languages.map(({ label, code, icon }) => (
                    <MenuItem key={code} onClick={changeLanguage(code)}>
                      {renderFlag(icon)}
                      {t(label)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default LanguageChanger;
