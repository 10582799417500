import documents from 'api/documents';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';

const parseAgreements = (rawAgreements: { [key: number]: boolean }) =>
  Object.entries(rawAgreements)
    .filter(([_, value]) => value)
    .map(([key]) => Number(key));

const useDocumentAgreementsUpdate = () => {
  const { patient } = usePatientContext();

  const updateDocumentAgreements = async (agreements: { [key: string]: boolean }) =>
    Promise.all(parseAgreements(agreements).map(agreementId => documents.updateDocumentsForContext(agreementId)({ patient: patient?.id })));

  return { updateDocumentAgreements };
};

export default useDocumentAgreementsUpdate;
