import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderTop: `thin solid ${theme.palette.grey[200]}`,
    textAlign: 'center',
    justifySelf: 'center',
    maxWidth: '540px',
    width: '100%',
  },
}));

export default useStyles;
