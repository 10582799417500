import React from 'react';

import { Paper } from '@mui/material';

import AppFooter from 'components/UI/organisms/AppFooter/AppFooter';
import AppTopBar from 'components/UI/organisms/AppTopBar/AppTopBar';

import useStyles from './LayoutUnauthorized.styles';

type Props = {
  children: React.ReactElement;
};

const LayoutUnauthorized: React.FC<Props> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.root}>
        <AppTopBar authorized={false} />
        <Paper className={classes.tile}>{children}</Paper>
      </div>
      <footer className={classes.footer}>
        <AppFooter />
      </footer>
    </>
  );
};

export default LayoutUnauthorized;
