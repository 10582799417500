import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  expandButton: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  flag: {
    border: `thin solid ${theme.palette.grey[200]}`,
    width: '1rem',
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
}));

export default useStyles;
