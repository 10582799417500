import React, { ReactElement, useState } from 'react';

import { Grid } from '@mui/material';
import axios, { AxiosRequestConfig } from 'axios';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormInputAutocomplete from 'components/UI/organisms/_formInputs/FormInputAutocomplete/FormInputAutocomplete';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import { Option } from 'constants/_types/DropownOptions';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect/useDidUpdateEffect';
import generateOptionsFormFlatList from 'services/generateOptionsFormFlatList/generateOptionsFormFlatList';
import generalMessages from 'translations/common/general.mjs';
import validationMessages from 'translations/common/validation.mjs';

import { PatientFormInput } from '../PatientDialog';

type PostCodeResponse = {
  data: { miejscowosc: string; ulica: string }[];
};

type Props = {
  form: UseFormReturn<PatientFormInput>;
};

const postCodePattern = /\d\d-\d\d\d/;

const PatientFormAddressData: React.FC<Props> = ({ form }): ReactElement => {
  const { t } = useTranslation();
  const [cityOptions, setCityOptions] = useState<Option[]>([]);
  const [streetOptions, setStreetOptions] = useState<Option[]>([]);

  const postcodeFieldValue = form.watch('postcode');

  const updateAddressDictionaries = async (postcode: string) => {
    // TODO extract it later
    const options: AxiosRequestConfig = {
      headers: {
        'X-TraceId': 'optional_abc123',
        Accept: 'application/json',
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST as string,
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY as string,
        Authorization: false,
      },
    };
    const url = `https://${process.env.REACT_APP_RAPID_API_HOST}/${postcode}`;

    try {
      const response: PostCodeResponse = await axios.get(url, options);

      const preparedCities = generateOptionsFormFlatList(response.data.map(({ miejscowosc }) => miejscowosc));
      const preparedStreets = generateOptionsFormFlatList(response.data.filter(({ ulica }) => !!ulica).map(({ ulica }) => ulica));

      if (!form.getValues('city')) {
        if (preparedCities.length === 1 && preparedCities[0]?.value) form.setValue('city', preparedCities[0].value as string);
        else form.setValue('city', '');
      }

      if (!form.getValues('street_name')) {
        if (preparedStreets.length === 0) form.setValue('street_name', '---');
        else if (preparedStreets.length === 1 && preparedStreets[0]?.value)
          form.setValue('street_name', preparedStreets[0].value as string);
        else form.setValue('street_name', '');
      }

      if (!form.getValues('country')) {
        form.setValue('country', 'Polska');
      }

      setCityOptions(preparedCities);
      setStreetOptions(preparedStreets);
    } catch (e) {
      // const { response } = e as AxiosError;
      // if (response?.status === 404) {
      //   form.setError('postcode', { message: t(validationMessages.invalid_postcode) });
      // }
    }
  };

  useDidUpdateEffect(() => {
    const isValid = postCodePattern.test(postcodeFieldValue);
    if (isValid) {
      form.clearErrors('postcode');
      updateAddressDictionaries(postcodeFieldValue);
    } else {
      setCityOptions([]);
      setStreetOptions([]);
    }
  }, [postcodeFieldValue]);

  return (
    <>
      <Grid item xs={6} sm={3}>
        <FormInputText
          rules={{
            pattern: {
              value: postCodePattern,
              message: t(validationMessages.invalid_postcode),
            },
          }}
          mask={{
            pattern: '##-###',
            definitions: {
              '#': /[0-9]/,
            },
          }}
          name='postcode'
          control={form.control}
          label={t(generalMessages.address.postCode)}
          required
        />
      </Grid>
      <Grid item xs={6} sm={5}>
        <FormInputAutocomplete
          freeSolo
          options={cityOptions}
          name='city'
          control={form.control}
          label={t(generalMessages.address.city)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputText name='country' control={form.control} label={t(generalMessages.address.country)} required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputAutocomplete
          freeSolo
          options={streetOptions}
          name='street_name'
          control={form.control}
          label={t(generalMessages.address.street)}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormInputText name='building_number' control={form.control} label={t(generalMessages.address.buildingNumber)} />
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormInputText name='apartment_number' control={form.control} label={t(generalMessages.address.apartmentNumber)} />
      </Grid>
    </>
  );
};

export default PatientFormAddressData;
