import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AuthTileFooter from 'components/UI/organisms/AuthTileFooter/AuthTileFooter';
import RegisterForm from 'components/UI/organisms/RegisterForm/RegisterForm';
import { BasePageProps } from 'constants/_types/BasePageProps';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import registerMessages from 'translations/specific/register.mjs';

const RegisterPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);

  const { t } = useTranslation();
  return (
    <>
      <RegisterForm />
      <AuthTileFooter>
        <Typography component='span'>{t(registerMessages.footer.login_link_label)}</Typography>{' '}
        <Link to={PATHS.AUTH_LOGIN}>{t(registerMessages.footer.login_link)}</Link>
      </AuthTileFooter>
    </>
  );
};

export default RegisterPage;
