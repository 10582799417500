import React, { useState } from 'react';

import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import users from 'api/users';
import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import PasswordValidation from 'components/UI/molecules/PasswordValidation/PasswordValidation';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import authMessages from 'translations/common/auth.mjs';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

type Props = {
  close: () => void;
  isOpen: boolean;
};

type ResetPasswordFormInput = {
  current_password: string;
  new_password: string;
  re_new_password: string;
};

const FORM_ID = 'PasswordChangeDialog_form';

const PasswordChangeDialog: React.FC<Props> = ({ isOpen, close }) => {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const [passwordValidators, setPasswordValidators] = useState<boolean>(false);

  const mutation = useMutation('Reset password', users.passwordChange(), {
    onSuccess: () => {
      enqueueSnackbar(t(snackbarMessages.success), { variant: 'success' });
      close();
    },
    onError: ({ response }) => {
      setApiError(response.data.current_password);
    },
  });

  const { control, handleSubmit, watch, getValues } = useForm<ResetPasswordFormInput>();
  watch();

  const onSubmit: SubmitHandler<ResetPasswordFormInput> = async data => {
    mutation.mutate({ ...data });
  };

  return (
    <DialogWrapper
      header={t(userSettingsMessages.passwordChange)}
      isOpen={isOpen}
      close={close}
      dialogProps={{
        maxWidth: 'sm',
      }}
      actions={
        <Button type='submit' variant='contained' disabled={mutation.isLoading || !passwordValidators} form={FORM_ID}>
          {t(generalMessages.save)}
        </Button>
      }
    >
      <DialogFormWrapper onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <SimpleFormWrapper>
          <FormInputPassword required name='current_password' control={control} label={t(authMessages.current_password)} />
          <FormInputPassword required name='new_password' control={control} label={t(authMessages.new_password)} />
          <FormInputPassword required name='re_new_password' control={control} label={t(authMessages.repeat_new_password)} />
          <PasswordValidation
            password={getValues().new_password}
            rePassword={getValues().re_new_password}
            setIsValid={setPasswordValidators}
          />
          <Typography align='center' variant='caption' color='error'>
            {apiError}
          </Typography>
        </SimpleFormWrapper>
      </DialogFormWrapper>
    </DialogWrapper>
  );
};

export default PasswordChangeDialog;
