import React from 'react';

import { FormControl, InputBaseComponentProps, InputLabel, OutlinedInput } from '@mui/material';
import { IMaskInput } from 'react-imask';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';

export type Mask = {
  pattern: string;
  definitions: {
    [key: string]: RegExp;
  };
};

type Props = {
  label: string;
  name: string;
  fullWidth?: boolean;
  error?: string | boolean;
  multiline?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  helperText?: string;
  number?: boolean;
  mask?: Mask;
  value?: string;
  inputProps?: InputBaseComponentProps;
};

type InputProps = InputBaseComponentProps & { mask?: Mask };

interface TextMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: Mask;
}

const TextMask: React.FC<TextMaskProps> = ({ mask, onChange, name, ...rest }) => (
  <IMaskInput
    {...rest}
    name={name}
    mask={mask.pattern}
    definitions={mask.definitions}
    onAccept={(value: any) => onChange({ target: { name, value } })}
    // overwrite
  />
);

class InputText extends React.PureComponent<Props> {
  customShrink = (): boolean | undefined => {
    const { value, mask } = this.props;
    return mask ? !!value : undefined;
  };

  getMergedInputProps = (): InputProps => {
    const { mask, name, inputProps } = this.props;
    const result: InputProps = {
      ...inputProps,
      'data-cy': `${name}_input`,
    };

    if (mask) result.mask = mask;
    return result;
  };

  render() {
    const { label, multiline, error, helperText, fullWidth = true, readOnly, disabled, number, mask, ...field } = this.props;

    return (
      <FormControl disabled={disabled} error={!!error} variant='outlined' fullWidth={fullWidth}>
        <InputLabel shrink={this.customShrink()} disabled={disabled} htmlFor={`text_input_${label}`}>
          {label}
        </InputLabel>
        <OutlinedInput
          notched={this.customShrink()}
          multiline={multiline}
          id={`text_input_${label}`}
          label={label}
          type={number ? 'number' : 'text'}
          readOnly={readOnly}
          disabled={disabled}
          inputComponent={mask ? (TextMask as any) : undefined}
          {...field}
          inputProps={this.getMergedInputProps()}
        />
        <InputFooter name={field.name} helperText={helperText} error={error} />
      </FormControl>
    );
  }
}

export default InputText;
