import React, { ReactElement } from 'react';

import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import PATHS from 'constants/router/PATHS';
import menuMessages from 'translations/specific/menu.mjs';

type MenuEntity = {
  label: string;
  icon: ReactElement;
  action?: () => void;
  to?: string;
  endOfSection?: boolean;
  cy?: string;
};

const useMenuContent = (): MenuEntity[] => {
  const { t } = useTranslation();
  const { logout } = useAuthContext();

  return [
    { label: t(menuMessages.elements.logout), action: logout, icon: <ExitToAppOutlinedIcon />, cy: 'menu_button_logout' },
    { label: t(menuMessages.elements.my_profile), to: PATHS.MY_PROFILE, icon: <PersonOutlineIcon />, endOfSection: true },
    { label: t(menuMessages.elements.start), to: PATHS.ROOT, icon: <HomeOutlinedIcon /> },
  ];
};

export default useMenuContent;
