const _normalizeNumber = (num: string): string => num.replace(/\D/g, '');

const comparePhoneNumbers = (rawNum1: number | string, rawNum2: number | string): boolean => {
  const num1 = rawNum1.toString();
  const num2 = rawNum2.toString();
  if (num1 === num2) return true;
  return _normalizeNumber(num1) === _normalizeNumber(num2);
};

export default comparePhoneNumbers;
