import axios from 'axios';

import { DefaultApiResponse } from './_types';

const endpoints = {
  accessToken: 'api/v1/symptom-checker/',
};

type GetAccessTokenResponse = {
  token: string;
};
type GetAccessTokenQuery = () => Promise<DefaultApiResponse<GetAccessTokenResponse>>;

const symptomChecker = {
  getAccessToken:
    (data: { patient: number | undefined }): GetAccessTokenQuery =>
    () =>
      axios.post<GetAccessTokenQuery, DefaultApiResponse<GetAccessTokenResponse>>(endpoints.accessToken, data),
};

export default symptomChecker;
