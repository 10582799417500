import React from 'react';

import { Card, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PATHS from 'constants/router/PATHS';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './NotFoundContent.styles';

const NotFoundContent: React.FC = () => {
  const { t } = useTranslation();

  const { classes } = useStyles();
  return (
    <Container className={classes.root} maxWidth='sm'>
      <Card className={classes.card}>
        <Typography variant='h2'>{t(generalMessages.pageNotFound)}</Typography>
        <Typography variant='h2'>
          <Link to={PATHS.ROOT}>{t(generalMessages.pageNotFoundBack)}</Link>
        </Typography>
      </Card>
    </Container>
  );
};

export default NotFoundContent;
