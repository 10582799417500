import React, { ChangeEvent } from 'react';

import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import pl from 'react-phone-input-2/lang/pl.json';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import { SupportedLanguages } from 'constants/translations/_types';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';

import useStyles from './InputPhoneNumber.styles';

import 'react-phone-input-2/lib/material.css';

type InputPhoneNumberProps = {
  label: string;
  fullWidth?: boolean;
  error?: string;
  multiline?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  helperText?: string;
  onChange: (value: string) => void;
  value: string;
};

const getLocalization = (code: SupportedLanguages) => {
  switch (code) {
    case 'pl':
      return pl;
    case 'es':
      return es;
    default:
      return undefined;
  }
};

const InputPhoneNumber: React.FC<InputPhoneNumberProps> = ({
  label,
  multiline,
  error,
  helperText,
  fullWidth = true,
  readOnly,
  disabled,
  ...field
}) => {
  const languageCode = useLanguageCode();

  const innerOnChange = (
    value: string,
    country: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    formattedValue: string,
  ): void => {
    const { onChange } = field;
    onChange(formattedValue);
  };
  const { classes } = useStyles({ error: !!error });

  return (
    <div>
      <PhoneInput
        countryCodeEditable={false}
        inputClass={classes.input}
        containerClass={classes.container}
        specialLabel={label}
        localization={getLocalization(languageCode)}
        country='pl'
        onChange={innerOnChange}
        value={field.value}
      />
      <InputFooter helperText={helperText} error={error} />
    </div>
  );
};

export default InputPhoneNumber;
