import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  drawerPaper: {
    display: 'unset !important',
    '-webkit-overflow-scrolling': 'touch',
    paddingTop: LAYOUT_SIZES.HEADER.lg,
    height: '100vh',
  },
  container: {
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

export default useStyles;
