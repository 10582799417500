import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    background: theme.palette.grey[200],
    padding: theme.spacing(5, 3),
    display: 'flex',
    justifyContent: 'space-between',
    gridGap: theme.spacing(6),
  },
  content: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 320px) auto minmax(0, 320px)',
    gridGap: theme.spacing(3, 8),
    '& a': {
      color: theme.palette.text.primary,
    },

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
      gridGap: theme.spacing(6),
    },
  },
  alignRight: {
    justifySelf: 'flex-end',

    [theme.breakpoints.down('md')]: {
      justifySelf: 'flex-start',
    },
  },
  contentBlock: {
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0,
    display: 'grid',
    gridGap: theme.spacing(1.5),
    alignContent: 'flex-start',
  },
  languageChange: {
    display: 'flex',
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
}));

export default useStyles;
