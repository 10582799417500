import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SupportedLanguages } from 'constants/translations/_types';

const useLanguageCode = () => {
  const { i18n } = useTranslation();

  const languageCode = useMemo(() => {
    if (i18n.language) return i18n.language.split('-')[0];
    return 'en';
  }, [i18n.language]);
  return languageCode as SupportedLanguages;
};

export default useLanguageCode;
