import React, { ReactElement, useEffect } from 'react';

import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputDropdown from 'components/UI/organisms/_formInputs/FormInputDropdown/FormInputDropdown';
import FormInputNumber from 'components/UI/organisms/_formInputs/FormInputNumber/FormInputNumber';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import genderOptions from 'constants/dropdownOptions/gender';
import patientDocumentTypes from 'constants/dropdownOptions/patientDocumentTypes';
import pesel, { Error } from 'helpers/pesel/pesel';
import translateOptions from 'services/translateOptions/translateOptions';
import validationMessages from 'translations/common/validation.mjs';
import patientMessages from 'translations/specific/patient.mjs';

import { PatientFormInput } from '../PatientDialog';

type Props = {
  form: UseFormReturn<PatientFormInput>;
  isEdit: boolean;
};

const isInvalid = (value?: string | number): boolean => isNil(value) || value === '';

const PatientFormPersonalData: React.FC<Props> = ({ form, isEdit }): ReactElement => {
  const { t } = useTranslation();

  const peselFieldValue = form.watch('pesel');
  const foreignDocumentFieldValue = form.watch('foreign_document');
  const genderFieldValue = form.watch('gender');
  useEffect(() => {
    if (!foreignDocumentFieldValue) form.clearErrors(['gender', 'dob']);
    if (foreignDocumentFieldValue) {
      form.setValue('pesel', '');
      form.trigger('pesel');
    }

    if (peselFieldValue && pesel.validate(peselFieldValue).isValid) {
      const { gender, dob } = pesel.getData(peselFieldValue);
      form.setValue('gender', gender);
      form.setValue('dob', format(dob, DATE_FORMATS.DISPLAY));
    } else {
      form.setValue('gender', genderFieldValue || '');
      form.setValue('dob', '');
    }
  }, [peselFieldValue, foreignDocumentFieldValue]);

  return (
    <Grid item container xs={20} spacing={3} columns={20}>
      <Grid item xs={20} sm={10} md={foreignDocumentFieldValue ? 7 : 8}>
        <FormInputCheckbox
          inputProps={{ disabled: isEdit }}
          label={t(patientMessages.fields.foreignDocument)}
          name='foreign_document'
          control={form.control}
        />
      </Grid>
      <Grid item xs={20} sm={10} md={4}>
        <FormInputNumber
          inputProps={{ disabled: isEdit || foreignDocumentFieldValue }}
          name='pesel'
          control={form.control}
          label={t(patientMessages.fields.pesel)}
          rules={{
            validate: (value: string) => {
              if (foreignDocumentFieldValue) return true;
              if (!value) return t(validationMessages.required_field);
              const { error } = pesel.validate(value);
              switch (error) {
                case Error.TOO_SHORT:
                  return t(validationMessages.pesel.too_short);
                case Error.TOO_LONG:
                  return t(validationMessages.pesel.too_long);
                case Error.INVALID:
                  return t(validationMessages.pesel.invalid);
                default:
                  return true;
              }
            },
          }}
        />
      </Grid>
      <Grid display={foreignDocumentFieldValue ? 'none' : 'flex'} item xs={10} md={4}>
        <FormInputText inputProps={{ disabled: true }} name='dob' control={form.control} label={t(patientMessages.fields.dateOfBirth)} />
      </Grid>
      <Grid display={foreignDocumentFieldValue ? 'flex' : 'none'} item xs={10} md={5}>
        <FormInputDate
          name='date_of_birth'
          control={form.control}
          label={t(patientMessages.fields.dateOfBirth)}
          disableFuture
          rules={{
            validate: (value?: string) => {
              if (isInvalid(value) && foreignDocumentFieldValue) return t(validationMessages.required_field);
              return true;
            },
          }}
        />
      </Grid>
      <Grid item xs={10} md={4}>
        <FormInputDropdown
          options={translateOptions(t, genderOptions)}
          inputProps={{ disabled: !foreignDocumentFieldValue }}
          name='gender'
          control={form.control}
          label={t(patientMessages.fields.gender)}
          rules={{
            validate: (value?: string) => {
              if (isInvalid(value) && foreignDocumentFieldValue) return t(validationMessages.required_field);
              return true;
            },
          }}
        />
      </Grid>
      {/* FOREIGN DOCUMENT FORM */}
      <Grid display={foreignDocumentFieldValue ? 'flex' : 'none'} item xs={12} spacing={3} container>
        <Grid item xs={12} sm={4}>
          <FormInputDropdown
            options={translateOptions(t, patientDocumentTypes)}
            inputProps={{ disabled: !foreignDocumentFieldValue }}
            name='foreign_document_type'
            control={form.control}
            label={t(patientMessages.fields.foreignDocumentType)}
            rules={{
              validate: (value?: string) => {
                if (isInvalid(value) && foreignDocumentFieldValue) return t(validationMessages.required_field);
                return true;
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormInputText
            inputProps={{ disabled: !foreignDocumentFieldValue }}
            name='foreign_document_number'
            control={form.control}
            label={t(patientMessages.fields.foreignDocumentNumber)}
            rules={{
              validate: (value?: string) => {
                if (isInvalid(value) && foreignDocumentFieldValue) return t(validationMessages.required_field);
                return true;
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormInputText
            inputProps={{ disabled: !foreignDocumentFieldValue }}
            name='foreign_document_country'
            control={form.control}
            label={t(patientMessages.fields.foreignDocumentCountry)}
            rules={{
              validate: (value?: string) => {
                if (isInvalid(value) && foreignDocumentFieldValue) return t(validationMessages.required_field);
                return true;
              },
            }}
          />
        </Grid>
      </Grid>
      {/* END OF FOREIGN DOCUMENT FORM */}
    </Grid>
  );
};

export default PatientFormPersonalData;
