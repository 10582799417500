import React from 'react';

import { Dialog, Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import LoaderOverlay from '../../../molecules/LoaderOverlay/LoaderOverlay';
import useStyles from './DialogLoader.styles';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => <Grow ref={ref} {...props} />,
);

type Props = { isOpen: boolean };

const DialogLoader: React.FC<Props> = ({ isOpen }) => {
  const { classes } = useStyles();
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onBackdropClick={() => null}
      scroll='body'
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <LoaderOverlay inner />
    </Dialog>
  );
};

export default DialogLoader;
