import React from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useSnackbar } from 'notistack';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import AuthContextProvider from 'components/context/AuthContext/AuthContext';
import ConfirmationDialogProvider from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import GlobalDialogsContextProvider from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import PatientContextProvider from 'components/context/PatientContext/PatientContext';
import Router from 'components/root/Router/Router';
import { AppEvents } from 'constants/_types/AppEvents';
import { SupportedLanguages } from 'constants/translations/_types';
import locales from 'constants/translations/locales';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import useSubscription from 'hooks/useSubscriptions/useSubscription';
import snackbarMessages from 'translations/common/snackbar.mjs';

const App = () => {
  const { t } = useTranslation();
  const langCode = useLanguageCode();
  const { enqueueSnackbar } = useSnackbar();
  const callback = () => {
    enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
  };
  useSubscription(AppEvents.API_MUTATION_ERROR, callback);
  useSubscription(AppEvents.API_FETCH_ERROR, callback);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC} language={langCode}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[langCode as SupportedLanguages]}>
        <AuthContextProvider>
          <PatientContextProvider>
            <ConfirmationDialogProvider>
              <GlobalDialogsContextProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </GlobalDialogsContextProvider>
            </ConfirmationDialogProvider>
          </PatientContextProvider>
        </AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </LocalizationProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
