import React, { createContext, useState, useContext, useMemo, useCallback, useEffect } from 'react';

import { useQuery } from 'react-query';

import { Patient } from 'api/_types';
import patients from 'api/patients';
import users, { MeResponse } from 'api/users';
import authProvider from 'services/authProvider/authProvider';

type Props = {
  children: React.ReactNode;
};

export type UserInfo = {
  isAuthorized?: boolean | null;
  id: number | null;
  email: string | null;
  phoneNumber: string | null;
  hasActivePolicy: boolean | null;
  hasPhoneVerified: boolean | null;
  hasFullAccount: boolean | null;
  patients: Patient[];
  hasObligatoryConsents: boolean | null;
  originBrand: string | null;
};

type AuthContextType = {
  login: (params: { access: string; refresh: string }, callback?: () => void) => void;
  logout: () => void;
  userInfo: UserInfo;
  refreshUserInfo: () => Promise<UserInfo>;
};

const emptyUserInfo: UserInfo = {
  isAuthorized: undefined,
  id: null,
  email: null,
  hasActivePolicy: null,
  hasPhoneVerified: null,
  phoneNumber: null,
  hasFullAccount: null,
  patients: [],
  hasObligatoryConsents: null,
  originBrand: null,
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = createContext<AuthContextType>(null!);

const parseUserDataResponse = (data: MeResponse): Omit<UserInfo, 'isAuthorized' | 'patients'> => ({
  id: data.id,
  email: data.email,
  phoneNumber: data.phone_number,
  hasActivePolicy: data.has_active_policy,
  hasPhoneVerified: data.has_phone_verified,
  hasFullAccount: data.has_full_account,
  hasObligatoryConsents: data.has_obligatory_consents,
  originBrand: data.origin_brand,
});

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(emptyUserInfo);

  const refreshUserInfo = async () => {
    const [{ data: userData }, { data: patientsData }] = await Promise.all([users.me()(), patients.getPatients()()]);

    console.log({ userData, patientsData });
    const newUserInfo: UserInfo = {
      ...parseUserDataResponse(userData),
      isAuthorized: true,
      patients: patientsData.results,
    };
    setUserInfo(newUserInfo);
    return newUserInfo;
  };

  const onLogin = async () => {
    await refreshUserInfo();
  };

  const onLogout = () => setUserInfo({ ...emptyUserInfo, isAuthorized: false });

  const onValidate = (validated: boolean) => {
    if (validated) onLogin();
    else onLogout();
  };

  const { data: userDataFromAPI } = useQuery('user data', users.me(), {
    refetchInterval: 1000 * 60 * 5,
    enabled: !!userInfo.isAuthorized,
  });

  useEffect(() => {
    if (userDataFromAPI) {
      const { data } = userDataFromAPI;
      const newUserInfo = parseUserDataResponse(data);
      setUserInfo(prev => ({ ...prev, ...newUserInfo }));
    }
  }, [userDataFromAPI]);

  useEffect(() => {
    authProvider.validate(onValidate);
  }, []);

  const login = useCallback(
    (data, callback) =>
      authProvider.login(data, async () => {
        await onLogin();
        if (callback) callback();
      }),
    [],
  );

  const logout = useCallback(() => authProvider.logout(onLogout), []);

  // Context Interface
  const value = useMemo(
    () => ({
      userInfo,
      login,
      logout,
      refreshUserInfo,
    }),
    [userInfo, login, logout, refreshUserInfo],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
