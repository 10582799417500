import React from 'react';

import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';

import useStyles from './ElementWithLoader.styles';

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

const ElementWithLoader: React.FC<Props> = ({ isLoading, children }) => {
  const { classes } = useStyles({ isLoading });
  return (
    <div className={classes.root}>
      {children}
      <div className={classes.overlay}>
        <LoaderOverlay inner />
      </div>
    </div>
  );
};

export default ElementWithLoader;
