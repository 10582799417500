import React from 'react';

import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';

import { DocumentForContext } from 'api/documents';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import getLabelForTermsAgreement from 'services/getLabelForTermsAgreement/getLabelForTermsAgreement';

import useStyles from './TermsAgreementForm.styles';

type TermsAgreementFormProps = {
  data: DocumentForContext[] | null;
  control: Control<any>;
  fillMissing?: boolean;
};

const TermsAgreementForm: React.FC<TermsAgreementFormProps> = ({ data, control, fillMissing }) => {
  const { classes } = useStyles();
  if (!data) return null;
  const agreementsToDisplay = fillMissing ? data.filter(({ is_required, is_main }) => is_required && is_main) : data;
  return (
    <Grid container className={classes.root}>
      {agreementsToDisplay.map(({ id, file, display_text, shouldBeDisabled, is_required }) => (
        <Grid item xs={12} key={id}>
          <FormInputCheckbox
            inputProps={{ disabled: shouldBeDisabled }}
            label={getLabelForTermsAgreement(display_text, file)}
            name={`terms.${id}`}
            control={control}
            required={is_required}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TermsAgreementForm;
