import axios from 'axios';

import { SymptomCheckerInterviewData } from 'constants/_types/SymptomCheckerInterviewData';
import { SymptomCheckerTriageLevels } from 'constants/_types/SymptomCheckerTriageLevels';
import createUrlWithParams, { Params } from 'helpers/createUrlWithParams/createUrlWithParams';

import { DefaultApiResponse, PaginatedApiResponse } from './_types';

const endpoints = {
  reports: 'api/v1/symptom-checker-reports/',
};

export type ReportData = {
  data: SymptomCheckerInterviewData;
  id: number;
  created_at: string;
  is_hidden: boolean;
  is_medical_record: boolean;
};

type GetReportQuery = () => Promise<DefaultApiResponse<ReportData>>;

export type ReportListData = {
  id: number;
  created_at: string;
  is_hidden: boolean;
  is_medical_record: boolean;
  triage_level: SymptomCheckerTriageLevels;
  condition_name: string;
};
type GetReportsQuery = () => Promise<PaginatedApiResponse<ReportListData>>;

type SaveReportInput = {
  data: SymptomCheckerInterviewData;
  is_hidden: boolean;
  is_medical_record: boolean;
  patient: number;
};
type SaveReportsQuery = (data: SaveReportInput) => Promise<DefaultApiResponse<SaveReportInput & { id: number }>>;

type UpdateReportInput = {
  patient: number;
  data?: SymptomCheckerInterviewData;
  is_hidden?: boolean;
  is_medical_record?: boolean;
};
type UpdateReportsQuery = (data: UpdateReportInput) => Promise<DefaultApiResponse<UpdateReportInput>>;

const reports = {
  getReport:
    (id: number): GetReportQuery =>
    () =>
      axios.get<GetReportQuery, DefaultApiResponse<ReportData>>(`${endpoints.reports}${id}/`),
  getReports:
    (params?: Params): GetReportsQuery =>
    () =>
      axios.get<GetReportsQuery, PaginatedApiResponse<ReportListData>>(createUrlWithParams(endpoints.reports, params)),
  saveReport: (): SaveReportsQuery => data =>
    axios.post<SaveReportsQuery, DefaultApiResponse<SaveReportInput & { id: number }>>(endpoints.reports, data),
  updateReport:
    (id: number): UpdateReportsQuery =>
    data =>
      axios.patch<UpdateReportsQuery, DefaultApiResponse<UpdateReportInput>>(`${endpoints.reports}${id}/`, data),
};

export default reports;
