import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import plLocale from 'date-fns/locale/pl';

import { SupportedLanguages } from './_types';

type Locales = {
  [key in SupportedLanguages]: Locale;
};

const locales: Locales = {
  en: enLocale,
  pl: plLocale,
  es: esLocale,
};

export default locales;
