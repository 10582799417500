import { Locale as DateFnsLocale } from 'date-fns';
import { enUS, es, pl } from 'date-fns/locale';

import { SupportedLanguages } from 'constants/translations/_types';

type Locale = {
  [key in SupportedLanguages]: DateFnsLocale;
};

const locale: Locale = { pl, es, en: enUS };

const options: { weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 } = { weekStartsOn: 1 };

if (locale.pl.options) locale.pl.options = { ...locale.pl.options, ...options };
if (locale.es.options) locale.es.options = { ...locale.es.options, ...options };
if (locale.en.options) locale.en.options = { ...locale.en.options, ...options };

export default locale;
