import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isLoading: boolean }>()((theme, { isLoading }) => ({
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    background: theme.palette.background.default,
    display: isLoading ? 'block' : 'none',
  },
}));

export default useStyles;
