import { createTheme } from '@mui/material/styles';

import { SymptomCheckerTriageLevels } from 'constants/_types/SymptomCheckerTriageLevels';
import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

declare module '@mui/material/styles' {
  interface Palette {
    probability: {
      high: Palette['primary'];
      medium: Palette['primary'];
      low: Palette['primary'];
    };
    triage: {
      [key in SymptomCheckerTriageLevels]: Palette['primary'];
    };
    branding: {
      interrisk: {
        highlightedText: string;
        shadow: string;
        primary: string;
      };
    };
  }

  interface PaletteOptions {
    probability: {
      high: PaletteOptions['primary'];
      medium: PaletteOptions['primary'];
      low: PaletteOptions['primary'];
    };
    triage: {
      [key in SymptomCheckerTriageLevels]: PaletteOptions['primary'];
    };
    branding: {
      interrisk: {
        highlightedText: string;
        shadow: string;
        primary: string;
      };
    };
  }
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#13b4c4',
      light: '#d9f4f6',
      contrastText: '#fff',
    },
    probability: {
      high: {
        main: '#fd6d6c',
      },
      medium: {
        main: '#fcc52d',
      },
      low: {
        main: '#1ec98f',
      },
    },
    triage: {
      consultation: {
        main: '#4dadf7',
        contrastText: '#fafafa',
      },
      consultation_24: {
        main: '#4dadf7',
        contrastText: '#fafafa',
      },
      emergency: {
        main: '#fcc41a',
        contrastText: '#fafafa',
      },
      emergency_ambulance: {
        main: '#ff6a67',
        contrastText: '#fafafa',
      },
      self_care: {
        main: '#1ac98e',
        contrastText: '#fafafa',
      },
    },
    branding: {
      interrisk: {
        highlightedText: '#DA3832',
        shadow: '#1A788815',
        primary: '#EAF9FA',
      },
    },
  },
  mixins: {
    toolbar: {
      '@media (min-width:0px) and (orientation: landscape)': { height: LAYOUT_SIZES.HEADER.lg },
      '@media (min-width:600px)': { height: LAYOUT_SIZES.HEADER.lg },
      height: LAYOUT_SIZES.HEADER.lg,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.background.default,
          color: theme.palette.getContrastText(theme.palette.background.default),
          borderBottom: `thin solid ${theme.palette.grey[200]}`,
          zIndex: theme.zIndex.drawer + 1,
        }),
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '4px',
          border: `thin solid ${theme.palette.grey[200]}`,
        }),
      },
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(14px, 10px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '1px 4px 1px 6px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
        multiline: {
          padding: '0',
        },
        input: {
          padding: '10px 14px',
        },
        notchedOutline: {
          top: '-4px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {},
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: ({ theme }) => ({
          borderColor: theme.palette.grey[400],
          background: theme.palette.background.default,
          color: theme.palette.text.secondary,

          '&:hover': {
            background: theme.palette.background.default,
            borderColor: theme.palette.grey[600],
          },
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2, 3),
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[700],
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          fontSize: '28px',
        },
        action: {
          marginRight: 0,
          alignItems: 'center',
        },
      },
    },
  },

  typography: {
    h1: {
      fontSize: '2rem',
    },
    h2: {
      color: '#3e5060',
      fontSize: '1.5rem',
    },
    h3: {
      color: '#3e5060',
      fontSize: '1.25rem',
    },
    h4: {
      color: '#13b4c4',
      fontSize: '.9rem',
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default defaultTheme;
