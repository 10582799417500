import React, { useMemo } from 'react';

import { isAfter, isBefore } from 'date-fns';
import { Control, Controller, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputDate from 'components/UI/molecules/InputDate/InputDate';
import validationMessages from 'translations/common/validation.mjs';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  helperText?: string;
  required?: boolean;
  defaultValue?: Date | null;
  rules?: ValidationRule<any>;
  disablePast?: boolean;
  disableFuture?: boolean;
  inputProps?: {
    fullWidth?: boolean;
    multiline?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    timePicker?: boolean;
    minDate?: Date;
    maxDate?: Date;
  };
};

const FormInputDate: React.FC<Props> = ({
  control,
  label,
  helperText,
  name,
  defaultValue = null,
  inputProps = {},
  rules = {},
  required = false,
  disablePast = false,
  disableFuture = false,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({
      required: { value: required, message: t(validationMessages.required_field) },
      ...rules,
      validate: (value: Date) => {
        const isInvalidDate = value && value.toString() === 'Invalid Date' ? t(validationMessages.invalid_dateformat) : false;

        if (isInvalidDate) return isInvalidDate;

        const additionalValidation = [];
        const today = new Date().setHours(0, 0, 0, 0);
        if (rules.validate) additionalValidation.push(rules.validate(value, control));
        if (disablePast && isBefore(value, today)) additionalValidation.push(t(validationMessages.invalid_date_past));
        if (disableFuture && isAfter(value, today)) additionalValidation.push(t(validationMessages.invalid_date_future));

        return additionalValidation.find(e => typeof e === 'string');
      },
    }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rulesProp}
      render={({ field, fieldState }) => (
        <InputDate
          error={fieldState.error?.message}
          label={label}
          helperText={helperText}
          disablePast={disablePast}
          disableFuture={disableFuture}
          {...inputProps}
          {...field}
        />
      )}
    />
  );
};

export default FormInputDate;
