import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AuthTileFooter from 'components/UI/organisms/AuthTileFooter/AuthTileFooter';
import LoginForm from 'components/UI/organisms/LoginForm/LoginForm';
import { BasePageProps } from 'constants/_types/BasePageProps';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import loginMessages from 'translations/specific/login.mjs';

const LoginPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);
  const { t } = useTranslation();
  return (
    <>
      <LoginForm />
      <AuthTileFooter>
        <Typography component='span'>{t(loginMessages.footer.register_link_label)}</Typography>{' '}
        <Link to={PATHS.AUTH_REGISTER}>{t(loginMessages.footer.register_link)}</Link>
      </AuthTileFooter>
    </>
  );
};

export default LoginPage;
