import { useMemo } from 'react';

import { useQueries } from 'react-query';

import documents, { DocumentContext, DocumentForContext } from 'api/documents';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';

const useDocumentsAgreementsQuery = (contexts: DocumentContext[], options?: object) => {
  const languageCode = useLanguageCode();
  const safeOptions = options || {};

  const data = useQueries(
    contexts.map(context => ({
      queryKey: ['Get documents to accept', context, languageCode],
      queryFn: documents.getDocumentsForContext(context),
      refetchOnWindowFocus: false,
      ...safeOptions,
    })),
  );

  const documentsAgreements: { [key in DocumentContext]?: DocumentForContext[] } | null = useMemo(() => {
    if (!data) return null;
    if (data.some(({ isLoading }) => isLoading)) return null;

    const resultsArray = data.map(({ data: contextData }) => {
      if (!contextData?.data) return null;
      return contextData.data.filter(({ language_code }) => language_code === languageCode);
    });

    const initial: { [key: string]: DocumentForContext[] | null } = {};
    return resultsArray.reduce((acc, curr, index) => {
      acc[contexts[index]] = curr;
      return acc;
    }, initial);
  }, [data]);

  return { documentsAgreements };
};

export default useDocumentsAgreementsQuery;
