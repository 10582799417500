import React, { createContext, useState, useContext, useMemo } from 'react';

import { Patient } from 'api/_types';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';

type Props = {
  children: React.ReactNode;
};

type PatientContextType = {
  patient: Patient;
  changePatient: (newPatient: number) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const PatientContext = createContext<PatientContextType>(null!);

const PatientContextProvider: React.FC<Props> = ({ children }) => {
  const { userInfo } = useAuthContext();
  const [patientNumber, setPatientNumber] = useState<number>(0);

  const changePatient = (number: number) => setPatientNumber(number);

  const value = useMemo(() => ({ patient: userInfo.patients[patientNumber], changePatient }), [userInfo, patientNumber, changePatient]);
  return <PatientContext.Provider value={value}>{children}</PatientContext.Provider>;
};

export const usePatientContext = () => useContext(PatientContext);

export default PatientContextProvider;
