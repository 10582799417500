import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

type St = (lib: { [key: string]: object }, key?: string) => string;

const useSafeTranslation = () => {
  const { t } = useTranslation();

  const st: St = (lib, key) => {
    if (!key) return '';
    const translationKey = get(lib, key) as string[];
    return translationKey ? t(translationKey) : '';
  };

  return { st };
};

export default useSafeTranslation;
