import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ tiny?: boolean; error?: boolean }>()((theme, { tiny, error }) => ({
  root: {
    position: 'relative',
  },
  title: {
    position: 'absolute',
    top: 0,
    color: theme.palette.grey[600],
    fontSize: '.75rem',
    transform: 'translateY(-50%)',
  },
  footer: {
    marginLeft: theme.spacing(4),
  },
  label: {
    fontSize: tiny ? '.75rem' : '1rem',
  },
  asterisk: {
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
