import React from 'react';

import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';

import useStyles from './MenuButton.styles';

type Props = {
  icon: React.ReactElement;
  isActive?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  cy?: string;
};

const MenuButton: React.FC<Props> = ({ icon, isActive, children, onClick, to, cy }) => {
  const linkProps = to ? { to, component: NavLink } : {};
  const { classes } = useStyles();
  return (
    <Button variant='outlined' className={classes.root} startIcon={icon} onClick={onClick} {...linkProps} data-cy={cy}>
      <span className={classes.label}>{children}</span>
    </Button>
  );
};

export default MenuButton;
