import React, { useMemo } from 'react';

import { Control, Controller, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputText, { Mask } from 'components/UI/molecules/InputText/InputText';
import validationMessages from 'translations/common/validation.mjs';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  helperText?: string;
  required?: boolean;
  defaultValue?: string;
  customError?: string;
  rules?: ValidationRule<any>;
  inputProps?: {
    fullWidth?: boolean;
    multiline?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
  };
  mask?: Mask;
};

const FormInputText: React.FC<Props> = ({
  control,
  label,
  helperText,
  name,
  defaultValue = '',
  inputProps = {},
  rules = {},
  required = false,
  customError,
  mask,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_field) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rulesProp}
      render={({ field, fieldState }) => (
        <InputText
          error={customError || fieldState?.error?.message}
          label={label}
          helperText={helperText}
          mask={mask}
          {...inputProps}
          {...field}
        />
      )}
    />
  );
};

export default FormInputText;
