import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `thin solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1.75),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.75),
  },
  paginationNumber: {
    height: '1.75rem',
    width: '1.75rem',
  },
}));

export default useStyles;
