import React from 'react';

import { BasePageProps } from 'constants/_types/BasePageProps';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const MyProfilePolicySubscriptionsPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);
  // eslint-disable-next-line no-console
  console.log('MyProfilePolicySubscriptionsPage');
  return (
    <div>
      <b>Work in progress</b>
    </div>
  );
};

export default MyProfilePolicySubscriptionsPage;
