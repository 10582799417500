import axios from 'axios';

import { PersonalData, PersonalDataUnassigned, DefaultApiResponse } from './_types';

const endpoints = {
  personalData: 'api/v1/personal-data/',
};

type UpdatePersonalDataQuery = (data: PersonalDataUnassigned) => Promise<DefaultApiResponse<PersonalData>>;

const patients = {
  putPersonalData:
    (id: number): UpdatePersonalDataQuery =>
    data =>
      axios.put<UpdatePersonalDataQuery, DefaultApiResponse<PersonalData>>(`${endpoints.personalData}${id}/`, data),
  patchPersonalData:
    (id: number): UpdatePersonalDataQuery =>
    data =>
      axios.patch<UpdatePersonalDataQuery, DefaultApiResponse<PersonalData>>(`${endpoints.personalData}${id}/`, data),
};

export default patients;
