const dashboardMessages = {
  sickCard: {
    header: [ 'dashboard>>sickCard>>header', 'Zachorowałem' ],
    virtual_doctor: {
      header: [ 'dashboard>>sickCard>>virtual_doctor>>header', 'Wirtualny lekarz' ],
      description: [ 'dashboard>>sickCard>>virtual_doctor>>description', 'Sprawdź swoje objawy, otrzymaj pomoc, podejmij dalsze krok' ]
    },
    stationary_visit: {
      header: [ 'dashboard>>sickCard>>stationary_visit>>header', 'Umów wizytę lub badania' ],
      description: [ 'dashboard>>sickCard>>stationary_visit>>description', 'Do lekarza pierwszego kontaktu lub specjalisty\nBadania laboratoryjne i obrazowe\nInne badania diagnostyczne' ]
    },
    teleconsultation: {
      header: [ 'dashboard>>sickCard>>teleconsultation>>header', 'Umów telekonsultacje' ],
      description: [ 'dashboard>>sickCard>>teleconsultation>>description', 'Jak najszybciej lub na określoną godzinę' ]
    }
  },
  treatmentCard: {
    header: [ 'dashboard>>treatmentCard>>header', 'Leczę się' ],
    prescription: {
      header: [ 'dashboard>>treatmentCard>>prescription>>header', 'Zamów receptę' ],
      description: [ 'dashboard>>treatmentCard>>referral>>description', 'W ramach kontynuacji leczenia na podstawie dokumentacji medycznej' ]
    },
    referral: {
      header: [ 'dashboard>>treatmentCard>>>>header', 'Zamów skierowanie' ],
      description: [ 'dashboard>>treatmentCard>>>>description', 'Lorem ipsum dolor sit amet.' ]
    }
  },
  reportsCard: {
    header: [ 'dashboard>>reportsCard>>header', 'Raporty wirtualnego lekarza' ],
    deleteConfirmation: {
      title: [ 'dashboard>>reportsCard>>deleteConfirmationTitle', 'Ukryj raport' ],
      body: [ 'dashboard>>reportsCard>>deleteConfirmationBody', 'Czy jesteś pewien, że chcesz ukryć raport z tablicy?' ],
    },
    actions: {
      hide: [ 'dashboard>>reportsCard>>actions>>hide', 'Usuń z tablicy' ],
      show: [ 'dashboard>>reportsCard>>actions>>show', 'Pokaż na tablicy' ],
      download: [ 'dashboard>>reportsCard>>actions>>download', 'Pobierz' ],
      private: [ 'dashboard>>reportsCard>>actions>>private', 'Zmień na prywatny' ],
      public: [ 'dashboard>>reportsCard>>actions>>public', 'Zmień na publiczny' ],
    }
  },
  alerts: {
    fillYourData: {
      content: [ 'dashboard>>alerts>>fillYourData>>content', 'Uzupełnij swoje dane profilowe ({{missingFields}}) niezbędne do sprawdzenia aktywnej polisy.' ],
      description: [ 'dashboard>>alerts>>fillYourData>>description', 'Uzupełnij swoje dane profilowe niezbędne do sprawdzenia aktywnej polisy.' ],
      actionButton: [ 'dashboard>>alerts>>fillYourData>>actionButton', 'Uzupełnij dane' ]
    },
    missingPolicy: {
      content: [ 'dashboard>>alerts>>missingPolicy>>content', 'Brak aktywnej polisy.' ],
      description: [ 'dashboard>>alerts>>missingPolicy>>description', 'Upewnij się czy twoja polisa jest aktywna i sprawdź ponownie.' ],
    }
  }
}

export default dashboardMessages
