import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import generalMessages from 'translations/common/general.mjs';

import useStyles from './LabelValueField.styles';

type Props = {
  label: string;
  value?: string;
};

const LabelValueField: React.FC<Props> = ({ label, value }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Typography component='label' className={classes.label} variant='body1'>
        {label}
      </Typography>
      <Typography>{value || t(generalMessages.noData)}</Typography>
    </div>
  );
};

export default LabelValueField;
