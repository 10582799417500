import React from 'react';

import { PaletteColor } from '@mui/material';
import { Circle, Document, Font, Page, Path, Svg, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { SymptomCheckerTriageLevels } from 'constants/_types/SymptomCheckerTriageLevels';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import locale from 'constants/dates/locale';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import { resolveProbabilityColor, resolveProbabilityName } from 'services/_dictionaryResolvers/resolveProbability/resolveProbability';
import theme from 'styles/theme';
import symptomCheckerReportsMessages from 'translations/specific/symptomCheckerReports.mjs';

import getStyles from './SymptomCheckerReportPdf.styles';

type Props = {
  createdAt: string;
  gender: string;
  age: number;
  symptoms: {
    present: { name: string; id: string }[];
    absent: { name: string; id: string }[];
    unknown: { name: string; id: string }[];
  };
  riskFactors: { name: string; id: string }[];
  visitedPlaces: { name: string; id: string }[];
  reportedSymptoms: { name: string; id: string }[];
  conditions: { common_name: string; id: string; probability: number }[];
  triageBaseText: {
    header: string;
    content: string;
  };
  triageBrandText: string;
  triageLevel: SymptomCheckerTriageLevels;
  triageStyling: {
    color: PaletteColor;
  };
};

Font.register({
  family: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  format: 'truetype',
  src: '/fonts/Roboto.ttf',
});
Font.register({
  family: 'Roboto-Bold',
  format: 'truetype',
  src: '/fonts/Roboto-Bold.ttf',
});

const addFallback = (str?: string): string => str || ' ';

const resolveTriageIconForReport = (triage: SymptomCheckerTriageLevels, styles: any) => {
  const svgProps = {
    viewBox: '0 0 64 64',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: '#FFF',
    style: styles.triageIcon,
  };
  switch (triage) {
    case SymptomCheckerTriageLevels.CONSULTATION:
      return (
        <Svg {...svgProps}>
          <Circle stroke='#FFF' stroke-width='2' fill='none' cx='50' cy='42' r='2' />
          <Path
            stroke='#FFF'
            strokeWidth='2'
            fill='none'
            d='M6 19c0 9.941 8.059 18 18 18s18-8.059 18-18M6 19V8a4 4 0 0 1 4-4h2m24 0h2a4 4 0 0 1 4 4v11M24 37v13m0 0c0 7.18 5.82 13 13 13s13-5.82 13-13'
          />
          <Circle stroke='#FFF' fill='none' cx='50' cy='42' r='8' />
          <Path
            stroke='#FFF'
            strokeWidth='2'
            fill='none'
            d='M35.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-2a2.5 2.5 0 1 1 0-5h2zm-23 0h2a2.5 2.5 0 1 1 0 5h-2a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z'
          />
        </Svg>
      );
    case SymptomCheckerTriageLevels.CONSULTATION_24:
      return (
        <Svg {...svgProps}>
          <Path
            fill='none'
            d='M3 17c0 7.732 6.268 14 14 14s14-6.268 14-14M3 17V9a4 4 0 0 1 4-4M27 5a4 4 0 0 1 4 4v8M26.75 3a.25.25 0 0 1 .25.25v3.5a.25.25 0 0 1-.25.25l-1.725-.001L25 7a2 2 0 0 1-1.995-1.85L23 5a2 2 0 0 1 2-2h1.75zM7.25 3H9a2 2 0 1 1 0 4H7.25A.25.25 0 0 1 7 6.75v-3.5A.25.25 0 0 1 7.25 3z'
            stroke='#FFF'
            strokeWidth='2'
          />
          <Circle fill='none' stroke='#FFF' strokeWidth='2' cx='49' cy='55' r='6' />
          <Circle strokeWidth='2' fill='#fff' cx='49' cy='55' r='2' />
          <Path
            fill='#ffffff'
            d='M50.795 22h-9.083v-1.55l4.5-4.907c.65-.724 1.112-1.328 1.387-1.814.275-.485.413-.972.413-1.46 0-.644-.181-1.164-.543-1.56-.362-.395-.849-.593-1.461-.593-.73 0-1.296.222-1.698.668-.402.445-.603 1.054-.603 1.827h-2.254c0-.822.187-1.563.56-2.222.375-.658.91-1.17 1.606-1.535.696-.365 1.498-.547 2.407-.547 1.312 0 2.346.329 3.104.988.757.658 1.136 1.57 1.136 2.732 0 .674-.19 1.38-.57 2.12-.38.739-1.004 1.578-1.87 2.519L44.523 20.2h6.272V22zm9.342-4.815h1.68v1.81h-1.68V22h-2.254v-3.006h-5.836l-.065-1.373 5.827-9.129h2.328v8.693zm-5.789 0h3.535v-5.64l-.167.296-3.368 5.344z'
            fillRule='nonzero'
          />
          <Path
            stroke='#ffffff'
            fill='none'
            d='M49 49v-8M49 41a8 8 0 1 0-16 0M17 46a8 8 0 0 0 8 8h0a8 8 0 0 0 8-8M17 46V31M33 46v-5'
            strokeWidth='2'
          />
        </Svg>
      );
    case SymptomCheckerTriageLevels.EMERGENCY:
      return (
        <Svg {...svgProps}>
          <Path strokeWidth='2' stroke='#ffffff' fill='none' d='M14 5h36a4 4 0 0 1 4 4v50H10V9a4 4 0 0 1 4-4zM0 59h64' />
          <Path
            strokeWidth='2'
            stroke='#ffffff'
            fill='none'
            d='M19 42h26a1 1 0 0 1 1 1v16H18V43a1 1 0 0 1 1-1zM30 21v-5.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V21h5.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H34v5.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V25h-5.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H30zM32 42v16'
          />
        </Svg>
      );
    case SymptomCheckerTriageLevels.SELF_CARE:
      return (
        <Svg {...svgProps}>
          <Path
            strokeWidth='2'
            stroke='#ffffff'
            fill='none'
            d='M28 26h8a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2z'
          />
          <Path strokeWidth='2' stroke='#ffffff' fill='none' d='M1 34L32 3l31 31' />
          <Path
            strokeWidth='2'
            stroke='#ffffff'
            fill='none'
            d='M8 33v25a3 3 0 0 0 3 3h13c.5 0 1-.5 1-1v-9m14 0v9c0 .5.5 1 1 1h13a3 3 0 0 0 3-3V33M39 51a7 7 0 0 0-14 0M44 15v-5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v13'
          />
        </Svg>
      );
    case SymptomCheckerTriageLevels.EMERGENCY_AMBULANCE:
      return (
        <Svg {...svgProps}>
          <Path strokeWidth='2' stroke='#ffffff' fill='none' d='M32 1v13m14 1l6-9m-34 9l-6-9' />
          <Circle strokeWidth='2' stroke='#ffffff' fill='none' cx='32' cy='37' r='7' />
          <Path
            strokeWidth='2'
            stroke='#ffffff'
            fill='none'
            d='M32 44v10m-8.862-34h17.724a8 8 0 0 1 7.948 7.092L52 55H12l3.19-27.908A8 8 0 0 1 23.138 20zM10 55h44a4 4 0 0 1 4 4v5H6v-5a4 4 0 0 1 4-4z'
          />
        </Svg>
      );
    default:
      return null;
  }
};

const SymptomCheckerReportPdf: React.FC<Props> = ({
  createdAt,
  gender,
  age,
  symptoms,
  riskFactors,
  visitedPlaces,
  reportedSymptoms,
  conditions,
  triageBaseText,
  triageBrandText,
  triageStyling,
  triageLevel,
}) => {
  const { t } = useTranslation();
  const langCode = useLanguageCode();

  const styles = getStyles(theme, triageStyling.color);

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.view}>
          <Text style={styles.h1}>{addFallback(t(symptomCheckerReportsMessages.header))}</Text>
          <Text style={styles.subtitle1}>
            {t(symptomCheckerReportsMessages.dateLabel)}
            {': '}
            {format(new Date(createdAt), DATE_FORMATS.DISPLAY_LONG, { locale: locale[langCode] })}
          </Text>
          <View style={styles.triageContainer}>
            <View style={styles.triageIndicator}>{resolveTriageIconForReport(triageLevel, styles)}</View>
            <View style={styles.triageMain}>
              <Text style={styles.triageRecommendation}>{addFallback(t(symptomCheckerReportsMessages.subheaders.recommendation))}</Text>
              <Text style={styles.triageHeader}>{addFallback(triageBaseText.header)}</Text>
              <Text style={styles.triageContent}>{addFallback(triageBaseText.content)}</Text>
            </View>
          </View>
          <View style={styles.triageBrandText}>
            <Text style={[styles.triageHeader, styles.triageBrandTextContent]}>{addFallback(triageBrandText)}</Text>
          </View>
          <Text style={styles.h2}>{addFallback(t(symptomCheckerReportsMessages.subheaders.patient))}</Text>
          <View style={styles.card}>
            <View style={styles.flex}>
              <View style={styles.flexChild}>
                <Text style={styles.cardTitle}>{addFallback(t(symptomCheckerReportsMessages.subheaders.gender))}</Text>
                <Text style={styles.cardValue}>{addFallback(gender)}</Text>
              </View>
              <View style={styles.flexChild}>
                <Text style={styles.cardTitle}>{addFallback(t(symptomCheckerReportsMessages.subheaders.age))}</Text>
                <Text style={styles.cardValue}>{addFallback(`${age}`)}</Text>
              </View>
            </View>
            <View style={styles.divider} />

            <View style={styles.cardSection}>
              <Text style={styles.cardTitle}>{t(symptomCheckerReportsMessages.subheaders.visitedPlaces)}</Text>
              {visitedPlaces.map(({ name, id }) => (
                <View style={styles.cardValueRow} key={id}>
                  <Svg style={styles.icon} viewBox='0 0 24 24'>
                    <Circle cx='12' cy='12' r='8' fill={theme.palette.primary.main} />
                  </Svg>
                  <Text style={styles.cardValue}>{addFallback(name)}</Text>
                </View>
              ))}
            </View>

            <View style={styles.cardSection}>
              <Text style={styles.cardTitle}>{addFallback(t(symptomCheckerReportsMessages.subheaders.riskFactors))}</Text>
              {riskFactors.map(({ name, id }) => (
                <View style={styles.cardValueRow} key={id}>
                  <Svg style={styles.icon} viewBox='0 0 24 24'>
                    <Circle cx='12' cy='12' r='8' fill={theme.palette.primary.main} />
                  </Svg>
                  <Text style={styles.cardValue}>{addFallback(name)}</Text>
                </View>
              ))}
            </View>
          </View>
          <Text style={styles.h2}>{addFallback(t(symptomCheckerReportsMessages.subheaders.interview))}</Text>
          <View style={styles.card}>
            <View style={styles.cardSection}>
              <Text style={styles.cardTitle}>{addFallback(t(symptomCheckerReportsMessages.subheaders.reportedSymptoms))}</Text>
              {reportedSymptoms.map(({ name, id }) => (
                <View style={styles.cardValueRow} key={id}>
                  <Svg style={styles.icon} viewBox='0 0 24 24'>
                    <Circle cx='12' cy='12' r='8' fill={theme.palette.primary.main} />
                  </Svg>
                  <Text style={styles.cardValue}>{addFallback(name)}</Text>
                </View>
              ))}
            </View>
            <View style={styles.cardSection}>
              <Text style={styles.cardTitle}>{addFallback(t(symptomCheckerReportsMessages.subheaders.present))}</Text>
              {symptoms.present.map(({ name, id }) => (
                <View style={styles.cardValueRow} key={id}>
                  <Svg style={styles.icon} viewBox='0 0 24 24'>
                    <Path d='M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z' fill={theme.palette.primary.main} />
                  </Svg>
                  <Text style={styles.cardValue}>{addFallback(name)}</Text>
                </View>
              ))}
            </View>
            <View style={styles.cardSection}>
              <Text style={styles.cardTitle}>{addFallback(t(symptomCheckerReportsMessages.subheaders.absent))}</Text>
              {symptoms.absent.map(({ name, id }) => (
                <View style={styles.cardValueRow} key={id}>
                  <Svg style={styles.icon} viewBox='0 0 24 24'>
                    <Path
                      d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'
                      fill={theme.palette.error.main}
                    />
                  </Svg>
                  <Text style={styles.cardValue}>{addFallback(name)}</Text>
                </View>
              ))}
            </View>
            <View style={styles.cardSection}>
              <Text style={styles.cardTitle}>{addFallback(t(symptomCheckerReportsMessages.subheaders.unknown))}</Text>
              {symptoms.unknown.map(({ name, id }) => (
                <View style={styles.cardValueRow} key={id}>
                  <Svg style={styles.icon} viewBox='0 0 24 24'>
                    <Path d='m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z' fill={theme.palette.grey[600]} />
                  </Svg>
                  <Text style={styles.cardValue}>{addFallback(name)}</Text>
                </View>
              ))}
            </View>
          </View>
          <Text style={styles.h2}>{addFallback(t(symptomCheckerReportsMessages.subheaders.results))}</Text>
          <View style={styles.card}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.cardTitle]}>{addFallback(t(symptomCheckerReportsMessages.subheaders.name))}</Text>
              <Text style={[styles.tableCell, styles.cardTitle]}>
                {addFallback(t(symptomCheckerReportsMessages.subheaders.probability))}
              </Text>
            </View>
            <View style={styles.divider} />
            {conditions.map(({ common_name, probability, id }) => (
              <View key={id} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.cardValue}>{addFallback(common_name)}</Text>
                </View>
                <View style={styles.tableCell}>
                  <View style={styles.flex}>
                    <View style={styles.barContainer}>
                      <View
                        style={[
                          styles.bar,
                          {
                            width: `${probability * 100}%`,
                            backgroundColor: resolveProbabilityColor(theme, probability),
                          },
                        ]}
                      />
                    </View>
                    <Text style={[styles.flexChild, styles.cardValue]}>{addFallback(resolveProbabilityName(t, probability))}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
        <Text style={styles.caption}>{addFallback(t(symptomCheckerReportsMessages.warning))}</Text>
      </Page>
    </Document>
  );
};

export default SymptomCheckerReportPdf;
