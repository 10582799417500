import React, { useMemo } from 'react';

import { Control, Controller, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputCheckbox from 'components/UI/molecules/InputCheckbox/InputCheckbox';
import validationMessages from 'translations/common/validation.mjs';

type Props = {
  label: string | JSX.Element;
  name: string;
  tiny?: boolean;
  control: Control<any>;
  title?: string;
  required?: boolean;
  defaultValue?: boolean;
  rules?: ValidationRule<any>;
  inputProps?: {
    fullWidth?: boolean;
    multiline?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    minDate?: Date;
  };
};

const FormInputCheckbox: React.FC<Props> = ({
  tiny,
  control,
  label,
  title,
  name,
  defaultValue = false,
  inputProps = {},
  rules = {},
  required = false,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_agreement) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rulesProp}
      render={({ field, fieldState }) => (
        <InputCheckbox
          tiny={tiny}
          error={fieldState.error?.message}
          label={label}
          title={title}
          required={required}
          {...inputProps}
          {...field}
        />
      )}
    />
  );
};

export default FormInputCheckbox;
