import { format } from 'date-fns';

import DATE_FORMATS from 'constants/dates/DATE_FORMATS';

const unknownDateToString = (value: Date | string | undefined, dateFormat?: string): string => {
  if (!value) return '';
  if (typeof value === 'string') return value;
  return format(value, dateFormat || DATE_FORMATS.DISPLAY);
};

export default unknownDateToString;
