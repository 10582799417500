import React, { ReactElement } from 'react';

import { Grid } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormInputPhoneNumber from 'components/UI/organisms/_formInputs/FormInputPhoneNumber/FormInputPhoneNumber';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import validatePhoneNumber from 'services/validatePhoneNumber/validatePhoneNumber';
import generalMessages from 'translations/common/general.mjs';
import validationMessages from 'translations/common/validation.mjs';
import patientMessages from 'translations/specific/patient.mjs';

import { PatientFormInput } from '../PatientDialog';

type Props = {
  form: UseFormReturn<PatientFormInput>;
};

const PatientFormBaseData: React.FC<Props> = ({ form }): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormInputText name='first_name' control={form.control} label={t(patientMessages.fields.firstName)} required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputText name='last_name' control={form.control} label={t(patientMessages.fields.lastName)} required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputText
          inputProps={{ disabled: true }}
          name='email'
          rules={{ pattern: { value: /@/, message: t(validationMessages.invalid_email) } }}
          control={form.control}
          label={t(patientMessages.fields.email)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputPhoneNumber
          name='phone_number'
          control={form.control}
          label={t(generalMessages.phoneNumber)}
          required
          rules={{
            validate: (value: string) => {
              const validationResult = validatePhoneNumber(value);
              return Array.isArray(validationResult) ? t(validationResult) : true;
            },
          }}
        />
      </Grid>
    </>
  );
};

export default PatientFormBaseData;
