const authMessages = {
  emailOrAuthIdentifier: [ 'auth>>email_or_auth_identifier', 'Email lub identyfikator użytkownika' ],
  email: [ 'auth>>email', 'Adres Email' ],
  password: [ 'auth>>password', 'Hasło' ],
  current_password: [ 'auth>>current_password', 'Obecne hasło' ],
  repeat_password: [ 'auth>>repeat_password', 'Powtórz hasło' ],
  new_password: [ 'auth>>new_password', 'Nowe hasło' ],
  repeat_new_password: [ 'auth>>repeat_new_password', 'Powtórz nowe hasło' ],
}

export default authMessages
