import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ error: boolean }>()((theme, { error }) => ({
  input: {
    width: '100% !important',
    borderRadius: '12px !important',
    padding: '12px 14px 12px 58px !important',

    '&:focus': {
      borderColor: `${error ? theme.palette.error.main : theme.palette.primary.main} !important`,
      boxShadow: `0 0 0 1px ${error ? theme.palette.error.main : theme.palette.primary.main} !important`,
    },

    borderColor: `${error ? theme.palette.error.main : theme.palette.grey[400]} !important`,
    boxShadow: `none !important`,
  },
  container: {
    '& .special-label': {
      color: `${error ? theme.palette.error.main : theme.palette.grey[700]} !important`,
      left: '10px !important',
      top: '-10px !important',
    },
  },
}));

export default useStyles;
