import React, { useState } from 'react';

import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import users from 'api/users';
import PasswordValidation from 'components/UI/molecules/PasswordValidation/PasswordValidation';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import PATHS from 'constants/router/PATHS';
import authMessages from 'translations/common/auth.mjs';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import resetPassword from 'translations/specific/resetPassword.mjs';

type Props = {
  uid: string;
  token: string;
};

type ResetPasswordFormInput = {
  new_password: string;
  re_new_password: string;
};

const ResetPasswordForm: React.FC<Props> = ({ token, uid }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);
  const [passwordValidators, setPasswordValidators] = useState<boolean>(false);

  const mutation = useMutation('Reset password', users.passwordReset(), {
    onSuccess: () => {
      navigate(PATHS.AUTH_RESET_PASSWORD_FORM_SUCCESS);
    },
    onError: ({ response }) => {
      setApiError(response.data.token);
    },
  });

  const { control, handleSubmit, watch, getValues } = useForm<ResetPasswordFormInput>();
  watch();

  const onSubmit: SubmitHandler<ResetPasswordFormInput> = async data => {
    if (!executeRecaptcha) enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    else {
      const recaptcha = await executeRecaptcha('Reset_password');
      mutation.mutate({ ...data, uid, token, recaptcha });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h3' component='h1' align='center' marginBottom={2}>
        {t(resetPassword.header_form)}
      </Typography>
      <SimpleFormWrapper>
        <FormInputPassword required name='new_password' control={control} label={t(authMessages.new_password)} />
        <FormInputPassword required name='re_new_password' control={control} label={t(authMessages.repeat_new_password)} />
        <PasswordValidation
          password={getValues().new_password}
          rePassword={getValues().re_new_password}
          setIsValid={setPasswordValidators}
        />
        <Typography align='center' variant='caption' color='error'>
          {apiError}
        </Typography>
        <Button variant='contained' disabled={mutation.isLoading || !!apiError || !passwordValidators} type='submit'>
          {t(generalMessages.submit)}
        </Button>
      </SimpleFormWrapper>
    </form>
  );
};

export default ResetPasswordForm;
