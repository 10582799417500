import React, { useEffect, useMemo, useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { differenceInCalendarYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Address, Patient } from 'api/_types';
import patients from 'api/patients';
import { ReactComponent as AvatarFemale } from 'assets/icons/avatar_female.svg';
import { ReactComponent as AvatarMale } from 'assets/icons/avatar_male.svg';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import CardTitle from 'components/UI/molecules/CardTitle/CardTitle';
import PatientDialog from 'components/UI/organisms/_dialogs/PatientDialog/PatientDialog';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import LabelValueField from 'components/UI/organisms/LabelValueField/LabelValueField';
import pesel from 'helpers/pesel/pesel';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useSafeTranslation from 'hooks/useSafeTranslation/useSafeTranslation';
import generalMessages from 'translations/common/general.mjs';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './PatientCard.styles';

type LocationState = {
  state: {
    openDialog?: boolean;
  };
};

const parseAddress = (address?: Address): string => {
  // TODO services?
  if (!address) return '';
  const lines = [
    `${address.street_name} ${address.building_number}${address.apartment_number ? `/${address.apartment_number}` : ''}`,
    `${address.postcode} ${address.city}`,
    address.country,
  ];

  return lines.filter(line => !!line.trim()).join('\n');
};

const PatientCard: React.FC = () => {
  const { t } = useTranslation();
  const { st } = useSafeTranslation();
  const { userInfo } = useAuthContext();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { refreshUserInfo } = useAuthContext();
  const { patient } = usePatientContext();
  const { state } = useLocation() as LocationState;

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const { data, isLoading, refetch } = useQuery(['Get patient info', patient?.id], patients.getPatient(patient?.id), {
    enabled: !!patient,
    onSuccess: () => {
      if (state?.openDialog) {
        openDialog();
        state.openDialog = false;
      }
    },
  });

  const refreshData = async ({ action }: { action: 'create' | 'update' }) => {
    if (action === 'create') await refreshUserInfo();
    if (action === 'update') await Promise.all([refetch(), refreshUserInfo()]);
  };

  useEffect(() => {
    if (!patient && state?.openDialog) {
      openDialog();
      state.openDialog = false;
    }
  }, [patient]);

  const userData = useMemo((): Patient | null => {
    if (!data) return null;
    return data?.data as Patient;
  }, [data]);

  const documentNumberProps = useMemo(() => {
    if (!userData || !userData.foreign_document) return { label: t(patientMessages.fields.pesel), value: userData?.pesel };
    return { label: t(patientMessages.otherDocumentNumber), value: userData.foreign_document_number };
  }, [userData]);

  const { classes } = useStyles();
  return (
    <>
      <Card>
        <CardTitle
          title={t(patientMessages.cardTitle)}
          rightAdornment={
            !!patient && (
              <IconButton onClick={openDialog} color='primary'>
                <EditOutlinedIcon />
              </IconButton>
            )
          }
        />
        <ElementWithLoader isLoading={isLoading}>
          <CardContent>
            {patient ? (
              <Grid container spacing={3}>
                <Grid item container xs={12} spacing={3} paddingBottom={2}>
                  <Grid container item xs='auto' alignItems='flex-end'>
                    {userData?.personal_data.gender === 'f' ? (
                      <AvatarFemale className={classes.avatar} />
                    ) : (
                      <AvatarMale className={classes.avatar} />
                    )}
                  </Grid>
                  <Grid item container xs spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='body1'>{`${userData?.personal_data.first_name} ${userData?.personal_data.last_name}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2'>{userData?.personal_data.email}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        {st(generalMessages, `gender.${userData?.personal_data.gender}`)}
                        {userData?.pesel &&
                          `, ${t(patientMessages.age, { age: differenceInCalendarYears(new Date(), pesel.getDOB(userData.pesel)) })}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabelValueField label={documentNumberProps.label} value={documentNumberProps.value} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabelValueField
                    label={t(patientMessages.fields.dateOfBirth)}
                    value={unknownDateToString(userData?.personal_data.date_of_birth)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabelValueField label={t(generalMessages.phoneNumber)} value={userInfo?.phoneNumber || ''} />
                </Grid>
                <Grid item xs={12}>
                  <LabelValueField label={t(patientMessages.fields.address)} value={parseAddress(userData?.personal_data.main_address)} />
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent='center' onClick={openDialog}>
                <Button variant='contained'>{t(patientMessages.addNew)}</Button>
              </Grid>
            )}
          </CardContent>
        </ElementWithLoader>
      </Card>
      {isDialogOpen && (
        <PatientDialog
          isOpen={isDialogOpen}
          close={closeDialog}
          isEdit={!!patient}
          initialData={patient && data?.data}
          refreshData={refreshData}
        />
      )}
    </>
  );
};

export default PatientCard;
