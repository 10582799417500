import React, { useEffect, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import auth from 'api/auth';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { GlobalDialog, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import PATHS from 'constants/router/PATHS';
import authMessages from 'translations/common/auth.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import loginMessages from 'translations/specific/login.mjs';

type LoginFormInput = {
  login_identifier: string;
  password: string;
};

const LoginForm: React.FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuthContext();
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string | null>(null);

  const mutation = useMutation('Login', auth.login(), {
    onSuccess: ({ data }) => {
      const { refresh, access, two_fa } = data;
      if (refresh && access) {
        login({ refresh, access });
      } else {
        addToGlobalDialogQueue({ type: GlobalDialog.PASS_TWO_FA, props: { token: two_fa } });
      }
    },
    onError: () => {
      setApiError(t(loginMessages.invalid_credentials));
    },
  });

  const { control, handleSubmit, watch } = useForm<LoginFormInput>();
  useEffect(() => {
    const subscription = watch(() => {
      setApiError(null);
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const loginIdentifierValue = watch('login_identifier');

  const onSubmit: SubmitHandler<LoginFormInput> = async data => {
    if (!executeRecaptcha) enqueueSnackbar(t(snackbarMessages.failure), { variant: 'error' });
    else {
      const recaptcha = await executeRecaptcha('Login');
      mutation.mutate({ ...data, recaptcha });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h3' component='h1' align='center' marginBottom={2}>
        {t(loginMessages.header)}
      </Typography>
      <SimpleFormWrapper>
        <FormInputText required name='login_identifier' control={control} label={t(authMessages.emailOrAuthIdentifier)} />
        <FormInputPassword required name='password' control={control} label={t(authMessages.password)} />
        <Typography align='right' variant='caption' justifySelf='right'>
          <Link to={PATHS.AUTH_RESET_PASSWORD_REQUEST} state={{ loginIdentifier: loginIdentifierValue }}>
            {t(loginMessages.forgot_password_link)}
          </Link>
        </Typography>
        <Typography data-cy='api_error' align='center' variant='caption' color='error'>
          {apiError}
        </Typography>
        <LoadingButton data-cy='login_button' variant='contained' loading={mutation.isLoading} disabled={!!apiError} type='submit'>
          {t(loginMessages.button_label)}
        </LoadingButton>
      </SimpleFormWrapper>
    </form>
  );
};

export default LoginForm;
