import React, { useCallback } from 'react';

import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';

import InputFooter from '../../atoms/InputFooter/InputFooter';
import useStyles from './InputCheckbox.styles';

type Props = {
  label: string | JSX.Element;
  title?: string;
  error?: string;
  disabled?: boolean;
  tiny?: boolean;
  value?: boolean;
  required?: boolean;
};

const InputCheckbox: React.FC<Props> = ({ label, title, error, tiny, required, ...field }) => {
  const { classes } = useStyles({ tiny, error: !!error });

  return (
    <div>
      <FormControl className={classes.root}>
        {label && (
          <Typography className={classes.title} variant='body1'>
            {title}
          </Typography>
        )}
        <FormControlLabel
          classes={{ label: classes.label }}
          control={<Checkbox checked={field.value} {...field} />}
          label={
            required ? (
              <span>
                {label}
                <span className={classes.asterisk}>*</span>
              </span>
            ) : (
              label
            )
          }
        />
      </FormControl>
      <InputFooter className={classes.footer} error={error} />
    </div>
  );
};

export default InputCheckbox;
