import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
      width: '100%',
      justifyContent: 'center',
      gridGap: theme.spacing(2),
    },
  },
}));

export default useStyles;
