// import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import PubSub from 'pubsub-js';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from 'components/root/App/App';
import axiosSetup from 'config/axios';
import prepareGlobalStyles from 'styles/globalStyles';
import theme from 'styles/theme';

import BrandContextProvider from './components/context/BrandContext/BrandContext';
import GeneralError from './components/root/GeneralError/GeneralError';
import LoaderOverlay from './components/UI/molecules/LoaderOverlay/LoaderOverlay';
import { AppEvents } from './constants/_types/AppEvents';

import 'config/i18n';

axiosSetup();

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: error => {
        PubSub.publish(AppEvents.API_MUTATION_ERROR, { error });
      },
    },
    queries: {
      onError: error => {
        PubSub.publish(AppEvents.API_FETCH_ERROR, { error });
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={muiCache}>
          <BrandContextProvider>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<LoaderOverlay />}>
                <GeneralError>
                  <CssBaseline />
                  <GlobalStyles styles={prepareGlobalStyles(theme)} />
                  <App />
                </GeneralError>
              </Suspense>
            </ThemeProvider>
          </BrandContextProvider>
        </CacheProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
