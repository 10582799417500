import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  card: {
    padding: theme.spacing(4),
    display: 'grid',
    gridGap: theme.spacing(3),
    textAlign: 'center',
  },
}));

export default useStyles;
