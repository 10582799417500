import React from 'react';

import { CircularProgress } from '@mui/material';

import useStyles from './LoaderOverlay.styles';

type Props = {
  inner?: boolean;
  minHeight?: string;
};

const LoaderOverlay: React.FC<Props> = ({ inner, minHeight }) => {
  const { classes, cx } = useStyles({ minHeight });
  return (
    <div className={cx(inner ? classes.inner : classes.cover)}>
      <CircularProgress />
    </div>
  );
};

export default LoaderOverlay;
