import { Gender } from 'constants/_types/Gender';

export enum Error {
  TOO_SHORT,
  TOO_LONG,
  INVALID,
}

type Data = {
  dob: Date;
  gender: Gender;
};

type Pesel = {
  validate: (value: string) => { isValid: boolean; error: Error | null };
  getData: (value: string) => Data;
  validateAndGetData: (value: string) => { isValid: boolean; error: Error | null; data: Data | null };
  getDOB: (value: string) => Date;
};

const validate = (value: string): { isValid: boolean; error: Error | null } => {
  if (!value) return { isValid: false, error: Error.TOO_SHORT };
  const CORRECT_PESEL_LENGTH = 11;
  if (value.length < CORRECT_PESEL_LENGTH) return { isValid: false, error: Error.TOO_SHORT };
  if (value.length > CORRECT_PESEL_LENGTH) return { isValid: false, error: Error.TOO_LONG };

  const WEIGHT = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  const controlNumber = parseInt(value.substring(10, 11), 10);

  for (let i = 0; i < WEIGHT.length; i += 1) {
    sum += parseInt(value.substring(i, i + 1), 10) * WEIGHT[i];
  }
  sum %= 10;
  return (10 - sum) % 10 === controlNumber ? { isValid: true, error: null } : { isValid: false, error: Error.INVALID };
};

const getGender = (value: string): Gender => {
  const genderNumber = value.substring(9, 10);
  return Number(genderNumber) % 2 ? Gender.MALE : Gender.FEMALE;
};

const isAfter2000 = (month: number): boolean => month > 20;

const getDOB = (value: string): Date => {
  const [yearString, monthNumber, dayNumber] = [value.substring(0, 2), Number(value.substring(2, 4)), Number(value.substring(4, 6))];
  const year = parseInt(isAfter2000(monthNumber) ? `20${yearString}` : `19${yearString}`, 10);
  const month = isAfter2000(monthNumber) ? monthNumber - 21 : monthNumber - 1;
  return new Date(year, month, dayNumber);
};

const getData = (value: string) => ({ gender: getGender(value), dob: getDOB(value) });

const validateAndGetData = (value: string) => {
  const validation = validate(value);
  if (!validation.isValid) return { ...validation, data: null };
  return { ...validation, data: getData(value) };
};

const pesel: Pesel = {
  validate,
  getDOB,
  getData,
  validateAndGetData,
};

export default pesel;
