import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AuthTileFooter from 'components/UI/organisms/AuthTileFooter/AuthTileFooter';
import ResetPasswordRequestForm from 'components/UI/organisms/ResetPasswordRequestForm/ResetPasswordRequestForm';
import { BasePageProps } from 'constants/_types/BasePageProps';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import resetPasswordMessages from 'translations/specific/resetPassword.mjs';

const ResetPasswordRequestPage: React.FC<BasePageProps> = ({ routeConfig }) => {
  usePageConfig(routeConfig);

  const { t } = useTranslation();
  return (
    <>
      <ResetPasswordRequestForm />
      <AuthTileFooter>
        <Link to={PATHS.AUTH_LOGIN}>{t(resetPasswordMessages.footer.login_link)}</Link>
      </AuthTileFooter>
    </>
  );
};

export default ResetPasswordRequestPage;
